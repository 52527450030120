import {
  routes,
  ROUTES_WITHOUT_PRINCIPAL_PREFIX,
} from 'controllers/ContentController/Routes';
import { Role } from 'shared/types/authorization';
import { MainFeatureType } from 'shared/types/features/FeatureIds';
import { Principal } from 'utils/firebase/collection';
import {
  getPrincipalSlugFromLocalStorage,
  storePrincipalIdSlugLocalStorage,
} from 'utils/localStorage.utils';

export const NO_PRINCIPAL_DEFINED_ERROR = 'No principal is set.';

type UseCurrentPrincipalResult = {
  currentPrincipal: Principal;
  setSelectedPrincipalIdWithRedirect: (
    prevPrincipal: string,
    selectedPrincipal: string,
    userId: string,
  ) => void;
};

/**
 * Hook to get the current principal
 * @param userId: id of current user
 * @returns current principal
 */
export const getCurrentPrincipal = (
  userId: string,
  principals: Principal[] | undefined,
): UseCurrentPrincipalResult => {
  let principal: Principal;
  const principalFromUrl = getPrincipalFromUrl(principals);

  const principalSlugFromLocalStorage =
    getPrincipalSlugFromLocalStorage(userId);

  const principalFromLocalStorage =
    principalSlugFromLocalStorage &&
    getPrincipalFromSlug(principalSlugFromLocalStorage, principals);

  if (principalFromUrl) {
    principal = principalFromUrl;
  } else if (principalFromLocalStorage) {
    principal = principalFromLocalStorage;
  } else {
    //Will be handled by error boundary component ErrorFallbackPrincipals
    throw new Error(NO_PRINCIPAL_DEFINED_ERROR);
  }

  return {
    currentPrincipal: principal,
    setSelectedPrincipalIdWithRedirect,
  };
};

//Setter with redirect for changing the principal on click
export const setSelectedPrincipalIdWithRedirect = (
  prevPrincipal: string,
  selectedPrincipalslug: string,
  userId: string,
) => {
  const pathArray = window.location.pathname.split('/');

  const newPathname = pathArray
    .map((elem) => (elem === prevPrincipal ? selectedPrincipalslug : elem))
    .join('/');

  storePrincipalIdSlugLocalStorage(userId, selectedPrincipalslug);
  window.location.replace(window.location.origin + newPathname);
};
/**
 * Gets principal from url path
 * @param principals {Principals} all available principals
 * @returns current principal {Principal} or undefined if no principal is set in url
 */
const getPrincipalFromUrl = (
  principals: Principal[] | undefined,
): Principal | undefined | null => {
  let currentPrincipal: Principal | undefined | null;

  if (window.location.pathname.length > 1) {
    //possible valid prinicpal-prefix or valid route (e.g. 'allnex' or 'performance' or 'global-users')
    const possibleValidSlug = window.location.pathname.split('/')[1];

    //array with all possible valid first layer of the routes (e.g. 'performance' or 'shared-documents')
    const urlRouteArray = routes('').routes.map(
      (route) => route.url.split('/')[1],
    );

    const current = principals?.find(
      (principal) => principal.slug === possibleValidSlug,
    );

    if (current) {
      currentPrincipal = current;
    } else if (urlRouteArray.includes(possibleValidSlug)) {
      currentPrincipal = null;
    }
  }

  return currentPrincipal;
};

/**
 * Gets principal from slug
 * @param principalSlug slug from principal you want to get
 * @param principals {Principals} all available principals
 * @returns current principal {Principal} or undefined if no principal is found to the given slug
 */
export const getPrincipalFromSlug = (
  principalSlug: string,
  principals: Principal[] | undefined,
): Principal | undefined => {
  return principals?.find(({ slug }) => slug === principalSlug);
};

/**
 * Caluculates and redirect to selected principal (if one is set in url or in localStorage)
 * @param userId id of current user
 * @param principals all assigned principals of the current user
 */
export const redirectToSelectedPrincipal = (
  userId: string,
  principals: Principal[],
  userRole?: Role,
): void => {
  const location = window.location;
  const principalURL = getPrincipalSlugFromLocalStorage(userId);
  // TODO: PARTNER AREA ROUTE ID
  const partnerAreaRoute = routes(principalURL).find(
    MainFeatureType.PORTAL_MEMBERS,
  );

  if (userRole === Role.PRINCIPAL_DEVELOPER && location.pathname === '/') {
    location.replace(partnerAreaRoute.url);
  } else if (location.pathname === '/') {
    //add principalId to the homepage
    location.replace(principalURL as string | URL);
  } else if (
    getPrincipalFromUrl(principals) === null &&
    !ROUTES_WITHOUT_PRINCIPAL_PREFIX.includes(
      '/' + location.pathname.split('/')[1],
    )
  ) {
    //add principalId to the url (not for all routes, e.g. not for /global-users, /principal-administration and /admin-center)
    const pathArray = location.pathname.split('/');
    pathArray[0] = '/' + principalURL;

    location.replace(location.origin + pathArray.join('/'));
  }
};
