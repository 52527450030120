import axios from 'axios';
import { CustomerDataByReportType } from 'components/EditDataAccessModal';
import { getFirebaseBearerToken } from './getFirebaseBearerToken';

export const updatePrincipalFeatureAccessRights = async (
  principalId: string,
  disabledFeatures: string[],
  maintenanceMode: string[],
  customerData: CustomerDataByReportType,
): Promise<void> => {
  await axios({
    url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/principals/${principalId}`,
    method: 'Patch',
    headers: {
      Authorization: `Bearer ${await getFirebaseBearerToken()}`,
    },
    data: {
      disabledFeatures,
      maintenanceMode,
      customerData,
    },
  });
};
