import { useMsal } from '@azure/msal-react';
import { Loading } from 'components/Loading';
import { PrincipalSelectionBar } from 'components/PrincipalSelectionBar';
import { SiteHeader } from 'components/SiteHeader';
import { useCurrentUser, usePrincipals } from 'hooks/queries';
import {
  getCurrentPrincipal,
  redirectToSelectedPrincipal,
} from 'lib/currentPrincipal';
import React, { useEffect, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { UserType } from 'shared/types/authorization';
import { Error404 } from '../../components/ErrorPages';
import { ScrollToTop } from '../../components/ScrollToTop';
import { SiteFooter } from '../../components/SiteFooter';
import { useLocalizationCookieHandler } from '../../hooks/useLocalizationCookieHandler';
import { usePrincipalFeatures } from '../../hooks/usePrincipalFeatures';
import { tracking } from '../../lib/tracking';
import { useWindowSize } from '../../shared/useWindowSize';
import { AcceptPoliciesModal } from './AcceptPoliciesModal';
import { findRouteDefintionByPathname, routes } from './Routes';

export const ContentController: React.FC = () => {
  const { user, role, isLoaded, userType, update } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(user.id, principals);
  const { instance } = useMsal();

  const localizationCookieHandler = useLocalizationCookieHandler();

  const calledOnce = useRef(false);
  const [windowWidth] = useWindowSize();

  const { data: principalFeatures } = usePrincipalFeatures(currentPrincipal.id);

  useEffect(() => {
    if (!calledOnce.current && windowWidth !== 0) {
      tracking.push('user', 'User Login', {
        loginStatus: 'logged in',
        type: role,
        principalID: currentPrincipal.id,
      });

      const {
        additionalInformation: { trackingCategory = '', trackingType = '' },
        name = '',
      } = findRouteDefintionByPathname(
        window.location.pathname,
        currentPrincipal.slug,
      );

      tracking.push('content', 'Content View', {
        category: trackingCategory,
        type: trackingType,
        content: name,
      });

      calledOnce.current = true;
    }
  }, [calledOnce, user, windowWidth, role, currentPrincipal, userType]);

  useEffect(() => {
    if (!principals) return;
    redirectToSelectedPrincipal(
      user.id,
      principals,
      user.roles[currentPrincipal.id],
    );
  }, [currentPrincipal, principals, user]);

  useEffect(() => {
    localizationCookieHandler.changeAppLanguage();
  }, [localizationCookieHandler]);

  if (window.location.pathname === '/')
    return (
      <Loading className="flex w-full items-center justify-center" loading />
    );

  const hideHeader = userType === UserType.EXTERNAL;

  return (
    <>
      <ScrollToTop />
      <AcceptPoliciesModal
        isHidden={!!user.acceptedTermsOfUse}
        onConfirm={() => update({ acceptedTermsOfUse: new Date() })}
        onCancel={() => {
          instance.logoutRedirect({ onRedirectNavigate: () => false });
        }}
      />
      <div className="grow flex-col">
        <div className="flex h-screen w-full flex-col lg:flex-row">
          {!hideHeader && (
            <div className="'sticky z-100 left-0 top-0 block lg:hidden">
              <PrincipalSelectionBar />
            </div>
          )}

          <SiteHeader />

          <div
            id="main-content"
            className="flex grow flex-col md:overflow-y-auto"
          >
            {!hideHeader && (
              <div className="'sticky z-100 left-0 top-0 hidden lg:block">
                <PrincipalSelectionBar />
              </div>
            )}
            <div className="relative flex shrink-0 grow flex-col">
              {isLoaded && (
                <Routes>
                  {routes(currentPrincipal.slug)
                    .routes.filter((route) =>
                      principalFeatures?.find(
                        (feature) =>
                          feature.id === route.routeId ||
                          feature.subFeatures?.find(
                            (subFeature) => subFeature.id === route.routeId,
                          ),
                      ),
                    )
                    .map(({ url, Component, routeId }) => {
                      const feature = principalFeatures?.find(
                        (route) => route.id === routeId,
                      );
                      return (
                        <Route
                          key={url}
                          path={url}
                          element={
                            <div className="main-page flex grow overflow-x-hidden">
                              <Component subFeatures={feature?.subFeatures} />
                            </div>
                          }
                        />
                      );
                    })}
                  <Route path="*" element={<Error404 />} />
                </Routes>
              )}
            </div>
            <SiteFooter />
          </div>
        </div>
      </div>
    </>
  );
};
