import { Alert } from 'components/Alert';
import { DropdownOptionProps } from 'components/Dropdown/helper/DropdownTypes';
import { EmptyState } from 'components/EmptyState';
import { Heading } from 'components/Heading';
import { LoadingBounce } from 'components/Loading';
import { MaintenanceState } from 'components/MaintenanceState/MaintenanceState';
import { PowerBiReport } from 'components/PowerBiReport';
import { Tab, Tabs } from 'components/Tabs';
import {
  MainFeatureComponent,
  routes,
} from 'controllers/ContentController/Routes';
import { iso3CountryCodesToRegionsDropdownOptions } from 'features/DigitalActivities/DigitalActivitiesModal';
import {
  PerformanceTabConfigKeys,
  useCRMMandates,
  useCurrentUser,
  useMarketSegmentDefinitions,
  useOpPerfTabConfigs,
  usePrincipals,
} from 'hooks/queries';
import { MarketSegmentDefinitions } from 'hooks/queries/useMarketSegmentDefinitions';
import { getCurrentPrincipal } from 'lib/currentPrincipal';
import { generateFilters } from 'lib/utils';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  MainFeatureType,
  SubFeatureType,
} from 'shared/types/features/FeatureIds';
import { countriesMappedByRegion, Regions } from 'utils/countries';
import { MandateFiltersForPrincipal } from 'utils/firebase/collection/firebase-collection-types';
import { FilterOptions, OPFilters } from './OPFilters';

const initialFilterOptions: Omit<FilterOptions, 'state'> = {
  country: [],
  marketSegment: [],
  portfolio: [],
  territory: [],
};

export const convertMandatesToFilterOptions = (
  userMandateFiltersForThisPrincipal: MandateFiltersForPrincipal,
  regions: Regions,
  marketSegmentDefinitions: MarketSegmentDefinitions,
): FilterOptions => {
  return Object.entries(userMandateFiltersForThisPrincipal).reduce(
    (newObj, [key, val]) => {
      if (key === 'country') {
        newObj[key as keyof FilterOptions] =
          iso3CountryCodesToRegionsDropdownOptions(val as string[], regions);
      } else if (key === 'marketSegment') {
        newObj[key as keyof FilterOptions] = (val as string[]).map((i) => ({
          name: marketSegmentDefinitions[i]?.name,
          value: i,
        }));
      } else if (key === 'portfolio') {
        newObj['portfolio'] = (val as string[]).map((i) => ({
          name: i,
          value: i,
        }));
      } else if (key === 'territory') {
        newObj['territory'] = (val as string[]).map((i) => ({
          name: i,
          value: i,
        }));
      }

      return newObj;
    },
    initialFilterOptions,
  );
};

const GLOBAL_USER_FILTER_OPTIONS = {
  country: [],
  portfolio: [],
  marketSegment: [],
  territory: [],
  hasAllMandates: true,
};

export const OperationalPerformance: MainFeatureComponent = ({
  subFeatures,
}) => {
  const { t } = useTranslation();
  const { tab: tabFromURL } = useParams<{
    tab: PerformanceTabConfigKeys | undefined;
  }>();
  const { user, isGlobalUser } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(user.id, principals);
  const { data: mandates } = useCRMMandates({
    principalPri: currentPrincipal.id,
  });
  let { data: opPerfTabConfigs } = useOpPerfTabConfigs();

  // bringing tabs into a specific order
  opPerfTabConfigs = opPerfTabConfigs
    ? {
        [SubFeatureType.PERFORMANCE_SALES]: opPerfTabConfigs?.sales,
        [SubFeatureType.PERFORMANCE_OPPORTUNITIES]:
          opPerfTabConfigs.opportunities,
        [SubFeatureType.PERFORMANCE_LEADS]: opPerfTabConfigs.leads,
        [SubFeatureType.PERFORMANCE_PRODUCT_SAMPLES]:
          opPerfTabConfigs['product-samples'],
        [SubFeatureType.PERFORMANCE_SUPPLY_CHAIN]:
          opPerfTabConfigs['supply-chain'],
      }
    : undefined;

  const navigate = useNavigate();
  const marketSegmentDefinitions = useMarketSegmentDefinitions();

  const [selectedTab, setSelectedTab] = useState<PerformanceTabConfigKeys>(
    tabFromURL ?? SubFeatureType.PERFORMANCE_SALES,
  );
  const [appliedFilters, setAppliedFilters] = useState<
    FilterOptions | undefined
  >();
  const [filterOptions, setFilterOptions] = useState<
    FilterOptions | undefined
  >();
  const [isReportLoaded, setIsReportLoaded] = useState<boolean>(false);
  const areTabsDisabled = !isReportLoaded;

  const hasCustomerData =
    !!currentPrincipal.customerData?.[selectedTab]?.length;

  useEffect(() => {
    const operationalPerformanceRoute = routes(currentPrincipal.slug).find(
      MainFeatureType.PERFORMANCE,
    );
    navigate(`${operationalPerformanceRoute.url}/${selectedTab}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const fullAvailableCountries = useMemo(
    () =>
      mandates?.countries.map((country) => ({
        ISO_A3: country.iso,
        name: country.name,
      })) ?? [],
    [mandates],
  );

  const regions = countriesMappedByRegion(fullAvailableCountries);

  useEffect(() => {
    const userMandateFiltersForThisPrincipal = isGlobalUser
      ? GLOBAL_USER_FILTER_OPTIONS
      : user?.mandateFilters?.[currentPrincipal.id];

    const countriesFromMandatesAreLoaded = fullAvailableCountries?.length;

    if (
      !userMandateFiltersForThisPrincipal ||
      !countriesFromMandatesAreLoaded ||
      filterOptions ||
      !mandates?.count.mandates
    )
      return;

    const availablePortfolios: DropdownOptionProps[] = mandates.portfolios.map(
      ({ name, id }) => ({
        name,
        value: id,
      }),
    );

    const availableTerritories: DropdownOptionProps[] | undefined =
      mandates.territories?.map((territory) => ({
        name: territory,
        value: territory,
      })) ?? [];

    if (userMandateFiltersForThisPrincipal.hasAllMandates || isGlobalUser) {
      const availableMarketSegments = mandates.marketSegments.map(
        ({ name, id }) => ({
          name,
          value: id,
        }),
      );

      const availableCountries: DropdownOptionProps[] = Object.entries(
        regions,
      ).map(([region, countries]) => {
        return {
          name: region,
          value: region,
          childOptions: countries.map(({ ISO_A3, name }) => ({
            name,
            value: ISO_A3,
          })),
        };
      });

      setFilterOptions({
        country: availableCountries,
        portfolio: availablePortfolios,
        marketSegment: availableMarketSegments,
        territory: availableTerritories,
      });

      return;
    }

    const newFilterOptions = convertMandatesToFilterOptions(
      userMandateFiltersForThisPrincipal,
      regions,
      marketSegmentDefinitions,
    );

    const userAvailablePortfolios = newFilterOptions.portfolio.map(
      (portfolio) => {
        return {
          ...portfolio,
          name:
            availablePortfolios.find(
              (portId) => portId.value === portfolio.value,
            )?.name ?? '',
        };
      },
    );

    newFilterOptions.portfolio = userAvailablePortfolios;

    setFilterOptions(newFilterOptions);
  }, [
    currentPrincipal,
    filterOptions,
    fullAvailableCountries,
    isGlobalUser,
    mandates,
    marketSegmentDefinitions,
    regions,
    user,
  ]);

  const powerBiFilters = useMemo(() => {
    let filtersToUse = appliedFilters;

    if (!filtersToUse) {
      const convertedCountriesToSingleLevelFilters =
        filterOptions?.country.reduce((acc, item) => {
          if (item.childOptions) {
            acc = acc.concat(
              item.childOptions.map((childOption) => ({
                ...childOption,
              })),
            );
          } else {
            acc.push({ ...item });
          }
          return acc;
        }, [] as DropdownOptionProps[]);

      filtersToUse = filterOptions
        ? {
            ...filterOptions,
            country: convertedCountriesToSingleLevelFilters || [],
          }
        : undefined;
    }

    const selectedCountryFilterValues =
      filtersToUse?.country.map((country: DropdownOptionProps) =>
        country.value.toString(),
      ) || [];

    const selectedMarketSegmentFilterValues =
      filtersToUse?.marketSegment.map((marketSegment: DropdownOptionProps) =>
        marketSegment.value.toString(),
      ) || [];

    const selectedPortfolios =
      filtersToUse?.portfolio.map((portfolio: DropdownOptionProps) =>
        portfolio.value.toString(),
      ) || [];

    const portfolioColumnName =
      selectedTab === SubFeatureType.PERFORMANCE_OPPORTUNITIES
        ? 'Products'
        : 'Items';

    const selectedTerritoryFilterValues = filtersToUse?.territory.map(
      (territory: DropdownOptionProps) => territory.value.toString(),
    );

    const convertedFilters = [
      generateFilters(
        'Market Segment',
        'Market Segment Code',
        selectedMarketSegmentFilterValues, // Change these to be codes (FH) rather than names
      ),
      generateFilters('Company', 'Country Code', selectedCountryFilterValues),
      generateFilters(portfolioColumnName, 'Portfolio Key', selectedPortfolios),
    ];

    if (
      selectedTerritoryFilterValues &&
      selectedCountryFilterValues.includes('USA')
    ) {
      convertedFilters.push(
        generateFilters(
          'Territory',
          'Territory',
          selectedTerritoryFilterValues,
        ),
      );
    }

    return convertedFilters;
  }, [appliedFilters, selectedTab, filterOptions]);

  const reportPage = hasCustomerData
    ? opPerfTabConfigs?.[selectedTab].reportPage_customerData
    : opPerfTabConfigs?.[selectedTab].reportPage;

  const isCurrentReportInMaintenanceMode = subFeatures?.find(
    ({ id }) => id === selectedTab,
  )?.maintenanceMode;

  const isCurrentReportEnabled = !!subFeatures?.find(
    ({ id }) => id === selectedTab,
  );

  useEffect(() => {
    if (isCurrentReportInMaintenanceMode || !isCurrentReportEnabled) {
      setIsReportLoaded(true);
    }
  }, [isCurrentReportEnabled, isCurrentReportInMaintenanceMode]);

  return (
    <div className="h-full w-full flex-1 bg-white px-4 md:px-6">
      <div className="max-w-xl-content mx-auto h-full">
        {opPerfTabConfigs && currentPrincipal && filterOptions ? (
          <>
            <div className="mb-7 pt-6 md:mb-3 md:pt-7" data-test="page-header">
              <div className="flex flex-col items-baseline justify-between md:flex-row md:space-x-2">
                <Heading
                  text={t('features:operationalPerformance:heading')}
                  margin={0}
                  level="h1"
                />
                <div className="flex w-full md:w-8/12 md:justify-end">
                  <div className="hidden md:block">
                    <OPFilters
                      filterOptions={filterOptions}
                      disableMarketSegments={
                        !!opPerfTabConfigs[selectedTab].disableMarketsegments
                      }
                      onChange={(newData: FilterOptions) => {
                        setAppliedFilters(newData);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Tabs
              activeTab={selectedTab}
              onTabChange={(tab) =>
                setSelectedTab(tab as PerformanceTabConfigKeys)
              }
              isDisabled={areTabsDisabled}
            >
              {Object.entries(opPerfTabConfigs)
                .filter(([key]) =>
                  subFeatures?.find(
                    (feature) => feature.id === key.toLowerCase(),
                  ),
                )
                .map(([key, value]) => (
                  <Tab
                    key={key}
                    tabKey={key}
                    title={value?.title?.toString()}
                  />
                ))}
            </Tabs>
            {isCurrentReportInMaintenanceMode ? (
              <MaintenanceState />
            ) : !isCurrentReportEnabled ? (
              <EmptyState
                message={t(
                  'features:operationalPerformance:unauthorizedMessage',
                )}
                hasBorder
              />
            ) : (
              <>
                <PowerBiReport
                  filters={powerBiFilters}
                  hideFilters={true}
                  onReportLoadingStatusChange={setIsReportLoaded}
                  reportName="operational_performance"
                  reportPage={reportPage}
                  role={currentPrincipal.manufacturerName}
                  tabTitle={opPerfTabConfigs[selectedTab].title}
                />
                {(selectedTab === SubFeatureType.PERFORMANCE_SALES ||
                  selectedTab === SubFeatureType.PERFORMANCE_OPPORTUNITIES) && (
                  <Alert
                    message={t('features:operationalPerformance:mandatesNote')}
                    flavour="info"
                  />
                )}
              </>
            )}
          </>
        ) : (
          <LoadingBounce />
        )}
      </div>
    </div>
  );
};
