import classnames from 'classnames';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { t } from 'i18next';

export type MaintenanceStateProps = {
  hasBorder?: boolean;
  heading?: string;
  description?: string;
};

export const MaintenanceState: React.FC<MaintenanceStateProps> = ({
  hasBorder = true,
  heading,
  description,
}) => {
  heading =
    heading ??
    t('features:admin-center:principalAdministration:maintenanceMode:heading');
  description =
    description ??
    t(
      'features:admin-center:principalAdministration:maintenanceMode:description',
    );
  return (
    <div
      className={classnames('flex flex-col items-center', {
        'border-grey-300 border p-6': hasBorder,
      })}
    >
      <span className="border-grey-300 h-20 w-20 bg-white pb-4">
        <Icon name="UnderMaintenance" />
      </span>
      <Heading text={heading} margin={0} level="h3" />
      <p className="text-grey-600 max-w-622px mt-1 whitespace-pre-line text-center">
        {description}
      </p>
    </div>
  );
};
