import { Icon } from 'components/Icon';
import {
  InternalRoute,
  MainMenuConfig,
} from 'controllers/ContentController/Routes';
import { TFunction } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import { BigMenuProps, siteHeaderStyles } from './SiteHeader';

type MainNavProps = BigMenuProps & {
  onMenuItemClick: () => void;
  getCurrentPath: () => string;
  getMenuItems: (menuSection: string) => (InternalRoute & {
    mainMenuConfig: MainMenuConfig;
  })[];
  isAuthEmpty: boolean;
  t: TFunction;
  newSharedDocuments: number;
};

export const MainNav: React.FC<MainNavProps> = ({
  onMenuItemClick,
  bigMenu,
  getCurrentPath,
  getMenuItems,
  isAuthEmpty,
  t,
  newSharedDocuments,
}) => {
  return (
    <>
      {!isAuthEmpty &&
        getMenuItems('primary').map(({ url, mainMenuConfig }) => (
          <Link
            onClick={onMenuItemClick}
            key={`menu-item-${mainMenuConfig.title}`}
            to={url}
            title={t(`linksOrButtons:navigation:${mainMenuConfig.title}`)}
            className={`flex h-8 items-center py-1 hover:bg-blue-100 md:h-auto md:flex-col md:justify-center md:py-0 lg:h-6 lg:py-1 ${
              bigMenu ? siteHeaderStyles.bigMenuLinks : ''
            } md:box-content md:w-4 md:px-3 lg:w-full lg:px-0
    ${mainMenuConfig.title === getCurrentPath() && 'bg-blue-100'}`}
          >
            <span
              className={`${siteHeaderStyles.menuItemSpanBase} ${
                bigMenu ? siteHeaderStyles.bigMenuIcons : 'lg:mx-auto'
              }
`}
            >
              <Icon
                name={mainMenuConfig.icon}
                title={t(`linksOrButtons:navigation:${mainMenuConfig.title}`)}
                notification={
                  mainMenuConfig.title === 'shared-documents'
                    ? newSharedDocuments
                    : undefined
                }
              />
            </span>
            <span
              className={`${
                bigMenu ? siteHeaderStyles.bigMenuLabels : 'md:hidden'
              } ml-2 md:ml-0`}
            >
              {t(`linksOrButtons:navigation:${mainMenuConfig.title}`)}
            </span>
          </Link>
        ))}
    </>
  );
};
