import { Input } from 'components/Input';
import { Modal } from 'components/Modal';
import { sorter } from 'features/GlobalUsers/GlobalUsers';
import { GlobalUserTableContent } from 'features/GlobalUsers/GlobalUserTableContent';
import { PrincipalTableContent } from 'features/GlobalUsers/PrincipalTableContent';
import { PortalUser, usePrincipals, useUsers } from 'hooks/queries';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Role, UserType } from 'shared/types/authorization';
import { SortingConfig } from 'shared/types/SortingConfig';
import { Principal } from 'utils/firebase/collection/firebase-collection-types';

export enum GlobalUserTableColumn {
  NAME = 'name',
  EDIT = 'editRights',
}

export type GlobalUserPrincipalRole = {
  [principalName: string]: Role;
};

type AddGlobalUserModalProps = {
  selectedUserToEdit?: PortalUser;
  initalStepNumber?: number;
  onCancel: () => void;
  onConfirm: (userId: string, principalRoles: GlobalUserPrincipalRole) => void;
};

export const AddGlobalUserModal: React.FC<AddGlobalUserModalProps> = ({
  selectedUserToEdit,
  initalStepNumber = 1,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const { users } = useUsers({ allInternalUsers: true });
  const { data: principals } = usePrincipals();
  const [selectedUser, setSelectedUser] = useState<PortalUser | undefined>(
    selectedUserToEdit,
  );
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [stepNumber, setStepNumber] = useState<number>(initalStepNumber);
  const [newPrincipalRoles, setNewPrincipalRoles] =
    useState<GlobalUserPrincipalRole>({});

  const [sortConfig, setSortConfig] = useState<
    SortingConfig<GlobalUserTableColumn>
  >({
    column: GlobalUserTableColumn.NAME,
    asc: true,
  });

  useEffect(() => {
    if (!Object.keys(newPrincipalRoles).length) {
      return principals?.forEach((principal) =>
        setNewPrincipalRoles((current: GlobalUserPrincipalRole) => ({
          ...current,
          [principal.id]: Role.GLOBAL_USER_VIEW,
        })),
      );
    }
  }, [principals, newPrincipalRoles]);

  useEffect(() => {
    const currentUserPrincipalValues =
      selectedUser?.global_user?.principalRoles;

    if (currentUserPrincipalValues) {
      const principalRolesToUpdate = Object.entries(
        currentUserPrincipalValues,
      ).reduce((current, [key, value]) => {
        current[key] = value;
        return current;
      }, {} as GlobalUserPrincipalRole);
      setNewPrincipalRoles((current: GlobalUserPrincipalRole) => ({
        ...current,
        ...principalRolesToUpdate,
      }));
    }
  }, [selectedUser]);

  const filteredUsers = useMemo(() => {
    return users
      ?.filter((user) => !user.isGlobalUser)
      ?.map(({ user }) => user)
      ?.filter((user) => user.userType !== UserType.EXTERNAL)
      ?.filter((user) => {
        if (searchTerm.length === 0) return true;
        return (
          user.name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
          user.email?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
      ?.sort((a, b) => sorter(a, b, sortConfig));
  }, [users, sortConfig, searchTerm]);

  const filteredPrincipals = useMemo(
    () =>
      principals
        ?.map((principal) => principal)
        ?.filter((principal) => {
          if (searchTerm.length === 0) return true;
          return principal.name
            ?.toLowerCase()
            ?.includes(searchTerm.toLowerCase());
        })
        ?.sort((a: Principal, b: Principal) => sorter(a, b, sortConfig)) ?? [],

    [principals, sortConfig, searchTerm],
  );

  const handleSortingChange = (targetColumn: GlobalUserTableColumn) => {
    const asc = sortConfig.column === targetColumn ? !sortConfig.asc : true;
    setSortConfig({ column: targetColumn, asc });
  };

  const handlePrinciplRightsChange = useCallback(
    (isChecked: boolean, principal: Principal) => {
      setNewPrincipalRoles((current: GlobalUserPrincipalRole) => ({
        ...current,
        [principal.id]: isChecked
          ? Role.GLOBAL_USER_EDIT
          : Role.GLOBAL_USER_VIEW,
      }));
    },
    [],
  );

  const onClickSelectUser = () => {
    setSearchTerm('');
    setStepNumber(2);
  };

  const onClickFinish = () => {
    if (!selectedUser) return;
    onConfirm(selectedUser.id, newPrincipalRoles);
  };

  const numberOfUsers = filteredUsers?.length === 1 ? 'one' : 'other';
  const numberOfPrincipals = filteredPrincipals?.length === 1 ? 'one' : 'other';

  return (
    <Modal
      isBig
      isCancelButtonHidden
      headingAlign="left"
      heading={t(
        `features:global-users:createGlobalUsers:${
          stepNumber === 1 ? 'step1Text' : 'step2Text'
        }`,
        {
          user: selectedUser?.name,
          showEditNumber: selectedUserToEdit ? '' : '2.',
        },
      )}
      confirmText={t(
        `features:global-users:createGlobalUsers:${
          stepNumber === 1 ? 'chooseUser' : 'finish'
        }`,
      )}
      onConfirm={stepNumber === 1 ? onClickSelectUser : onClickFinish}
      onCancel={onCancel}
      isConfirmButtonDisabled={!selectedUser}
    >
      <form>
        <div className="text-grey-700">
          <p className="mt-1">
            {t(
              `features:global-users:createGlobalUsers:${
                stepNumber === 1 ? 'step1Description' : 'step2Description'
              }`,
            )}
          </p>

          <div className="mt-1 flex w-full flex-col items-end">
            <div className="sm:w-300px">
              <Input
                placeholder={t(
                  stepNumber === 1
                    ? 'labels:userNameSearch'
                    : 'features:global-users:createGlobalUsers:step2Search',
                )}
                iconPosition="right"
                icon="Search"
                initialValue={searchTerm}
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
              />
            </div>
          </div>

          <div className="mt-1">
            <div className="grid grid-flow-row grid-cols-2">
              <div className="text-grey-600 flex items-center justify-start">
                {stepNumber === 1
                  ? t(
                      `features:global-users:createGlobalUsers:numberOfUsers_${numberOfUsers}`,
                      {
                        count: filteredUsers?.length || 0,
                      },
                    )
                  : t(
                      `features:global-users:createGlobalUsers:numberOfPrincipals_${numberOfPrincipals}`,
                      {
                        count: filteredPrincipals?.length,
                      },
                    )}
              </div>
            </div>
          </div>
          <div className="pt-4" data-testid="GlobalUserTableContainer">
            {stepNumber === 1 ? (
              <GlobalUserTableContent
                filteredUsers={filteredUsers}
                sortConfig={sortConfig}
                handleSortingChange={handleSortingChange}
                handleSelectUser={setSelectedUser}
                selectedUser={selectedUser}
              />
            ) : (
              <>
                <PrincipalTableContent
                  principals={filteredPrincipals}
                  initalRoleSelection={newPrincipalRoles}
                  sortConfig={sortConfig}
                  handleSortingChange={handleSortingChange}
                  handlePrinciplRightsChange={handlePrinciplRightsChange}
                />
                <div className="text-grey-600 text-2xs mt-3">
                  {t(
                    'features:global-users:createGlobalUsers:principalSelectionMessage',
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    </Modal>
  );
};
