import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { DropdownSingleSelect } from 'components/Dropdown/DropdownVariants/DropdownSingleSelect';
import { Modal } from 'components/Modal';
import { useCRMMandates } from 'hooks/queries';
import { updatePrincipalFeatureAccessRights } from 'lib/updatePrincipalFeatureAccessRights';
import { areStringArraysEqual } from 'lib/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MainFeatureType,
  SubFeatureType,
} from 'shared/types/features/FeatureIds';
import { countriesMappedByRegion } from 'utils/countries';
import { Principal } from 'utils/firebase/collection';
import { DropdownFilter } from './Dropdown/DropdownVariants/DropdownFilter';
import { DropdownOptionProps } from './Dropdown/helper/DropdownTypes';
import { Loading } from './Loading';

type EditDataAccessModalProps = {
  targetPrincipal: Principal;
  onClose: () => void;
};

type FeatureKey = ['disabled', 'allowed', 'maintenance'];

type FeatureStatuses = {
  [key in FeatureKey[number]]: string[];
};

type SubFeatureStatus = {
  [subFeatureId: string]: 'allowed' | 'disabled' | 'maintenance';
};
enum CustomerDataField {
  LEADS = 'leads',
  OPPORTUNITIES = 'opportunities',
  SAMPLES = 'product-samples',
}
export type CustomerDataByReportType = {
  [key in CustomerDataField]: string[];
};

const ModalStructure = [
  {
    feature: MainFeatureType.DASHBOARD,
    subFeatures: [
      {
        subFeatureId: SubFeatureType.DASHBOARD_KEY_SALES_NUMBERS,
      },
    ],
  },
  {
    feature: MainFeatureType.PERFORMANCE,
    subFeatures: [
      {
        subFeatureId: SubFeatureType.PERFORMANCE_SALES,
      },
      {
        subFeatureId: SubFeatureType.PERFORMANCE_OPPORTUNITIES,
        customerDataField: CustomerDataField.OPPORTUNITIES,
      },
      {
        subFeatureId: SubFeatureType.PERFORMANCE_LEADS,
        customerDataField: CustomerDataField.LEADS,
      },
      {
        subFeatureId: SubFeatureType.PERFORMANCE_PRODUCT_SAMPLES,
        customerDataField: CustomerDataField.SAMPLES,
      },
      {
        subFeatureId: SubFeatureType.PERFORMANCE_SUPPLY_CHAIN,
      },
      // Non conformities report is unavailable to the user for the moment so it is temporarily disabled here.
      // {
      //   subFeatureId: SubFeatureType.PERFORMANCE_NON_CONFORMITIES_REPORT,
      // },
    ],
  },
  {
    feature: MainFeatureType.DIGITAL_ACTIVITIES,
    subFeatures: [
      {
        subFeatureId: SubFeatureType.DIGITAL_ACTIVITIES_CP_INSIGHTS,
      },
      {
        subFeatureId: SubFeatureType.DIGITAL_ACTIVITIES_SUPPLIER_PAGES,
      },
    ],
  },
  {
    feature: MainFeatureType.MARKET_INTELLIGENCE,
    subFeatures: [
      {
        subFeatureId: SubFeatureType.MARKET_INTELLIGENCE_MARKET_INSIGHTS,
      },
      {
        subFeatureId:
          SubFeatureType.MARKET_INTELLIGENCE_EXPANSION_OPPORTUNITIES,
      },
      {
        subFeatureId:
          SubFeatureType.MARKET_INTELLIGENCE_SOCIAL_LISTENINGS_REPORT,
      },
    ],
  },
];

export const EditDataAccessModal: React.FC<EditDataAccessModalProps> = ({
  targetPrincipal,
  onClose,
}) => {
  const { t } = useTranslation('translation');

  const { data: mandatesData } = useCRMMandates({
    principalPri: targetPrincipal.id,
  });

  const queryClient = useQueryClient();
  const [subFeatureStatus, setSubFeatureStatus] = useState<SubFeatureStatus>();
  const [customerData, setCustomerData] = useState<CustomerDataByReportType>();

  const editDataAccessModalOptions: {
    name: string;
    value: keyof FeatureStatuses;
  }[] = [
    {
      name: t(
        'features:admin-center:principalAdministration:permissionOptions:disabled',
      ),
      value: 'disabled',
    },
    {
      name: t(
        'features:admin-center:principalAdministration:permissionOptions:allowed',
      ),
      value: 'allowed',
    },
    {
      name: t(
        'features:admin-center:principalAdministration:permissionOptions:maintenance',
      ),
      value: 'maintenance',
    },
  ];

  useEffect(() => {
    if (!targetPrincipal || subFeatureStatus || customerData) return;
    const initializeSubFeatureStatus: SubFeatureStatus = Object.values(
      SubFeatureType,
    ).reduce((acc: SubFeatureStatus, curr) => {
      acc[curr] = 'allowed';
      return acc;
    }, {});
    const newFeatures: SubFeatureStatus = {};
    const newCustomerData: CustomerDataByReportType = {
      leads: targetPrincipal.customerData?.leads ?? [],
      opportunities: targetPrincipal.customerData?.opportunities ?? [],
      ['product-samples']:
        targetPrincipal.customerData?.['product-samples'] ?? [],
    };

    targetPrincipal.disabledFeatures?.forEach((disabledFeatureId) => {
      newFeatures[disabledFeatureId] = 'disabled';
    });

    targetPrincipal.maintenanceMode?.forEach((maintenanceFeatureId) => {
      newFeatures[maintenanceFeatureId] = 'maintenance';
    });

    setCustomerData({ ...newCustomerData });
    setSubFeatureStatus({
      ...initializeSubFeatureStatus,
      ...newFeatures,
    });
  }, [targetPrincipal, subFeatureStatus, customerData]);

  const onDataAccessChangeHandler = (
    subFeatureId: string,
    selectedOption: FeatureKey[number],
  ): void => {
    if (subFeatureStatus?.[subFeatureId] === selectedOption) return;
    setSubFeatureStatus({
      ...subFeatureStatus,
      [subFeatureId]: selectedOption,
    });
  };

  const onCustomerDataChangeHandler = (
    customerDataField: CustomerDataField,
    selectedCountries: string[],
  ): void => {
    if (
      !customerData ||
      areStringArraysEqual(customerData[customerDataField], selectedCountries)
    )
      return;

    setCustomerData({
      ...customerData,
      [customerDataField]: selectedCountries,
    });
  };
  const resolveDropdownState = (subFeatureId: string) => {
    if (!subFeatureStatus) return;
    if (subFeatureStatus[subFeatureId] === 'disabled')
      return editDataAccessModalOptions[0];
    if (subFeatureStatus[subFeatureId] === 'maintenance')
      return editDataAccessModalOptions[2];

    return editDataAccessModalOptions[1];
  };

  const onConfirm = () => {
    if (!subFeatureStatus || !customerData) return;
    const disabledFeatures = Object.keys(subFeatureStatus).filter(
      (featureKey) => {
        return subFeatureStatus[featureKey] === 'disabled';
      },
    );
    const maintenanceFeatures = Object.keys(subFeatureStatus).filter(
      (featureKey) => {
        return subFeatureStatus[featureKey] === 'maintenance';
      },
    );

    updatePrincipalFeatureAccessRights(
      targetPrincipal.id,
      disabledFeatures,
      maintenanceFeatures,
      customerData,
    ).then(() => {
      queryClient.invalidateQueries({
        queryKey: ['principals'],
      });
      queryClient.invalidateQueries({
        queryKey: ['principalFeatureAccessRights'],
      });
      onClose();
    });
  };
  const fullAvailableCountries = useMemo(
    () =>
      mandatesData?.countries.map((country) => ({
        ISO_A3: country.iso,
        name: country.name,
      })) ?? [],
    [mandatesData?.countries],
  );

  const regions = countriesMappedByRegion(fullAvailableCountries);
  const availableCountries: DropdownOptionProps[] = Object.entries(regions).map(
    ([region, countries]) => {
      return {
        name: region,
        value: region,
        childOptions: countries.map(({ ISO_A3, name }) => ({
          name,
          value: ISO_A3,
        })),
      };
    },
  );

  const isLoading = !subFeatureStatus || !availableCountries.length;

  return (
    <Modal
      isBig
      isCancelButtonHidden={false}
      headingAlign="left"
      heading={t('features:admin-center:principalAdministration:modal:title', {
        principalName: targetPrincipal.name,
      })}
      confirmText={t(
        'features:admin-center:principalAdministration:modal:save',
      )}
      onConfirm={onConfirm}
      onCancel={onClose}
      isConfirmButtonDisabled={isLoading}
    >
      <div className="font-regular text-grey-700 pt-1 text-left text-base leading-4">
        <p className="mb-3">
          {t(`features:admin-center:principalAdministration:modal:description`)}
        </p>
      </div>
      <Loading loading={isLoading}>
        {!!subFeatureStatus &&
          ModalStructure.map(({ feature, subFeatures }) => {
            return (
              <React.Fragment key={feature}>
                <div>
                  <div className="flex justify-between">
                    <div className="text-grey-700 h-9 content-center text-base font-medium leading-4">
                      {t(
                        `features:admin-center:principalAdministration:modal:optionSections:${feature}`,
                      )}
                    </div>
                  </div>
                  <hr className="bg-grey-100 h-1px mx-auto w-48 rounded border-0" />
                </div>

                {subFeatures.map(({ subFeatureId, customerDataField }) => (
                  <React.Fragment key={subFeatureId}>
                    <div className="pl-4">
                      <div className="flex justify-between">
                        <div className="text-grey-700 font-regular content-center text-base leading-4">
                          {t(
                            `features:admin-center:principalAdministration:modal:optionSections:${subFeatureId}`,
                          )}
                        </div>
                        <div className="w-240px mb-2 mt-2">
                          <DropdownSingleSelect
                            initialSelection={resolveDropdownState(
                              subFeatureId,
                            )}
                            onChange={(option) =>
                              onDataAccessChangeHandler(
                                subFeatureId,
                                option.value as FeatureKey[number],
                              )
                            }
                            options={editDataAccessModalOptions}
                          />
                        </div>
                      </div>
                      <hr className="bg-grey-100 h-1px mx-auto w-48 rounded border-0" />
                    </div>

                    {!!customerDataField && (
                      <React.Fragment>
                        <div
                          className={classNames('pl-7', {
                            'text-grey-400':
                              subFeatureStatus[subFeatureId] !== 'allowed',
                            'text-grey-700':
                              subFeatureStatus[subFeatureId] === 'allowed',
                          })}
                        >
                          <div
                            className={classNames(
                              'font-regular mb-3 mt-3 content-center text-base',
                            )}
                          >
                            {t(
                              'features:admin-center:principalAdministration:modal:optionSections:includeCustomerData',
                            )}
                          </div>
                          <div className="flex justify-between">
                            <div className="font-regular w-[300px] text-xs">
                              {t(
                                'features:admin-center:principalAdministration:modal:optionSections:includeCustomerDataDescription',
                                {
                                  subFeature: t(
                                    `features:admin-center:principalAdministration:modal:optionSections:${subFeatureId}`,
                                  ),
                                },
                              )}
                            </div>
                            <div className="w-240px mb-3">
                              <DropdownFilter
                                typeAhead={true}
                                disabled={
                                  subFeatureStatus[subFeatureId] !== 'allowed'
                                }
                                label={t(
                                  'features:operationalPerformance:filters:country',
                                )}
                                selectAllLabel={t('placeholders:selectAll')}
                                deselectAllLabel={t('placeholders:deselectAll')}
                                options={availableCountries}
                                initialSelection={customerData?.[
                                  customerDataField
                                ].map((countryIso) => ({
                                  name:
                                    fullAvailableCountries.find(
                                      ({ ISO_A3 }) => ISO_A3 === countryIso,
                                    )?.name ??
                                    t(
                                      'features:admin-center:principalAdministration:modal:optionSections:unknownCountryIso',
                                    ),
                                  value: countryIso,
                                }))}
                                onChange={(option) =>
                                  onCustomerDataChangeHandler(
                                    customerDataField,
                                    option.map((option) =>
                                      option.value.toString(),
                                    ),
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <hr className="bg-grey-100 h-1px mx-auto w-48 rounded border-0" />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            );
          })}
      </Loading>
    </Modal>
  );
};
