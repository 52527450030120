import { Alert } from 'components/Alert';
import { DropdownSingleSelect } from 'components/Dropdown/DropdownVariants/DropdownSingleSelect';
import { LoadingBounce } from 'components/Loading';
import { Modal } from 'components/Modal';
import { routes } from 'controllers/ContentController/Routes';
import { APIKeyData } from 'features/DataConnection/types';
import { ApiUser, useApiKeys, useCurrentUser, useUsers } from 'hooks/queries';
import { generateMailParameter } from 'lib/mailGeneration';
import { Notification } from 'lib/notifications/notifications';
import { formatStandardDate } from 'lib/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Role, UserType } from 'shared/types/authorization';
import { MainFeatureType } from 'shared/types/features/FeatureIds';
import {
  ApiKeyTransferEmailItem,
  deleteUsers,
  EmailType,
  searchAdUsersByEmail,
  sendEmail,
} from 'utils/firebase/cloud-functions';
import { Principal } from 'utils/firebase/collection/firebase-collection-types';

type UserDeletionResults = {
  deleted: string[];
  notDelete: string[];
};

const developerHasKeys = (
  firebaseId: string,
  apiKeys: APIKeyData[] | undefined,
) => {
  return apiKeys?.some((key) => key.userDetails.firebaseId === firebaseId);
};

type UserToDelete = { id: string; name: string; role: Role };

type DeleteUsersModalProps = {
  onCancel: () => void;
  usersToDelete: UserToDelete[];
  selectedPrincipal: Principal;
  onComplete?: () => void;
};

export const DeleteUsersModal: React.FC<DeleteUsersModalProps> = ({
  onCancel,
  usersToDelete,
  selectedPrincipal,
  onComplete,
}) => {
  const { t } = useTranslation();
  const [deletedUserNames, setDeletedUserNames] = useState<string[]>([]);
  const [disableConfirmButton, setDisableConfirmButton] = useState(true);
  const [keyTransferPairs, setKeyTransferPairs] = useState<
    {
      from: string;
      to: string;
    }[]
  >([]);
  const { transferOwnershipOfAPIRecord, apiKeys, areAvailableKeysLoading } =
    useApiKeys();
  const { users } = useUsers();
  const currentUser = useCurrentUser();
  const isMulti = usersToDelete.length > 1;
  const developersToDelete = usersToDelete.filter(
    ({ role }) => role === Role.PRINCIPAL_DEVELOPER,
  );
  const nonDevelopersToDelete = usersToDelete.filter(
    ({ role }) => role !== Role.PRINCIPAL_DEVELOPER,
  );
  const numberOfDevelopersToDelete = developersToDelete.length;
  const numberOfDevelopersToDeleteWithKeys = developersToDelete.filter(
    ({ id }) => developerHasKeys(id, apiKeys),
  ).length;
  const numberOfNonDevelopersToDelete = nonDevelopersToDelete.length;
  const numberOfUsersToDelete = usersToDelete.length;
  const userNameOrNumber = isMulti
    ? usersToDelete.length
    : usersToDelete[0].name;
  const multiOrSingle = isMulti ? 'multi' : 'single';

  const availableDevelopersForKeyTransfer = useMemo(() => {
    const options = users
      ?.filter((user) => {
        const isPrincipalDeveloper = user.role === Role.PRINCIPAL_DEVELOPER;
        const isToBeDeleted = developersToDelete
          .map(({ id }) => id)
          .includes(user.user.id);
        return isPrincipalDeveloper && !isToBeDeleted;
      })
      .map(({ user }) => {
        return { name: user.name || 'No name', value: user.id };
      });
    options?.unshift({
      name: t('modals:deleteUsersModal:deleteWithoutTransferringKeys'),
      value: '0',
    });
    return options ?? [];
  }, [developersToDelete, t, users]);

  useEffect(() => {
    const isDeletingDevelopersButApiKeysNotLoaded =
      developersToDelete.length > 0 && areAvailableKeysLoading;
    if (isDeletingDevelopersButApiKeysNotLoaded) {
      setDisableConfirmButton(true);
      return;
    }

    const hasNotSelectedDestinationForApiKeys =
      keyTransferPairs.length !== numberOfDevelopersToDeleteWithKeys;
    const isDeletingAllDevelopers =
      availableDevelopersForKeyTransfer.length === 1;
    if (hasNotSelectedDestinationForApiKeys && !isDeletingAllDevelopers) {
      setDisableConfirmButton(true);
      return;
    }

    setDisableConfirmButton(false);
  }, [
    areAvailableKeysLoading,
    availableDevelopersForKeyTransfer.length,
    developersToDelete.length,
    keyTransferPairs.length,
    numberOfDevelopersToDelete,
    numberOfDevelopersToDeleteWithKeys,
    usersToDelete.length,
  ]);

  const transferKeysAndDeleteDisplayedUser = useCallback(() => {
    if (!numberOfUsersToDelete || !selectedPrincipal || !apiKeys) return;

    setDisableConfirmButton(true);

    keyTransferPairs.forEach(async (pair) => {
      if (pair.to === '0') return;
      const moveFromUserEmail = users?.find((user) => user.user.id == pair.from)
        ?.user.email;

      const moveToUserEmail = users?.find((user) => user.user.id == pair.to)
        ?.user.email;

      if (!moveFromUserEmail || !moveToUserEmail) {
        console.log(
          'Missing email ids of one or both (moveFromUser/ moveToUser)',
        );
        return;
      }
      const moveFromUser = await searchAdUsersByEmail(
        selectedPrincipal.id,
        moveFromUserEmail,
      ).then(({ data }) => data[0]);
      const moveToUser = await searchAdUsersByEmail(
        selectedPrincipal.id,
        moveToUserEmail,
      ).then(({ data }) => data[0]);
      const moveToUserDetails: ApiUser = {
        guid: moveToUser.guid,
        firebaseId: pair.to,
        email: moveToUserEmail,
      };

      if (!moveFromUser || !moveToUser) {
        console.log(
          'Missing active directory guids of one or both (moveFromUserGuid/ moveToUserGuid)',
        );
        return;
      }
      const keysToTransfer = apiKeys.filter(({ userDetails }) => {
        return userDetails.guid == moveFromUser.guid;
      });

      const keysEmailData: ApiKeyTransferEmailItem[] = [];

      keysToTransfer.forEach(
        ({ keyId, projectName, userDetails, scopes, dateExpiry }) => {
          transferOwnershipOfAPIRecord(
            keyId,
            moveToUserDetails,
            moveFromUser.guid,
          );

          keysEmailData.push({
            projectName: projectName,
            transferredFrom:
              userDetails.name ?? t('emails:apiKeyTransfer:unknownUser'),
            scopes: scopes.join(', ') || t('emails:apiKeyTransfer:noScopes'),
            expiryDate: formatStandardDate(dateExpiry) ?? t('labels:unknown'),
          });
        },
      );

      sendEmail({
        ...generateMailParameter({
          t,
          emailType: EmailType.API_KEY_TRANSFER,
          data: {
            sendTo: moveToUserEmail,
            subject: 'Test Email',
            transferredTo: moveToUser.name,
            transferredBy:
              currentUser.user.name ?? t('emails:apiKeyTransfer:unknownUser'),
            keysTransferData: keysEmailData,
            currentPrincipal: selectedPrincipal,
          },
        }),
      });
    });

    deleteUsers({
      userIdsToDelete: usersToDelete.map((user) => user.id),
      currentPrincipalId: selectedPrincipal.id,
    })
      .then(({ data }) => data)
      .then(
        (
          data:
            | { success: boolean; result: UserDeletionResults }
            | { code: string },
        ) => {
          setDisableConfirmButton(false);

          if ('code' in data || ('success' in data && !data.success)) {
            Notification({
              message: t('modals:deleteUsersModal:error:system'),
            });
            return;
          }

          if (data.result.notDelete.length > 0) {
            Notification({
              message: t('modals:deleteUsersModal:error:notOnThisPrincipal', {
                undeletedUser: data.result.notDelete[0],
              }),
            });
          }

          setDeletedUserNames(data.result.deleted);
          if (onComplete) {
            onComplete();
          }
        },
      );
  }, [
    apiKeys,
    currentUser.user.name,
    keyTransferPairs,
    numberOfUsersToDelete,
    onComplete,
    selectedPrincipal,
    t,
    transferOwnershipOfAPIRecord,
    users,
    usersToDelete,
  ]);

  return (
    <>
      {!!deletedUserNames.length && (
        <Navigate
          to={
            routes(selectedPrincipal.slug).find(MainFeatureType.PORTAL_MEMBERS)
              .url
          }
          state={{ deletedUserNames }}
        />
      )}
      <Modal
        heading={t(`modals:deleteUsersModal:title:${multiOrSingle}`)}
        confirmText={t(`modals:deleteUsersModal:confirm:${multiOrSingle}`)}
        onConfirm={transferKeysAndDeleteDisplayedUser}
        onCancel={onCancel}
        isConfirmButtonDisabled={disableConfirmButton}
        headingAlign="left"
        isBig
      >
        <div className="pt-1 text-left">
          <p className="mb-4">
            {t(
              `modals:deleteUsersModal:description:${currentUser.userType}:${multiOrSingle}`,
              {
                userNameOrNumber,
                selectedPrincipalName: selectedPrincipal.name,
              },
            )}
          </p>

          {currentUser.userType !== UserType.EXTERNAL && (
            <Alert
              message={t('modals:deleteUsersModal:info')}
              iconTop={true}
              flavour="warning"
              icon="InfoCircle"
              hasBorder={false}
              iconColor="orange-300"
            />
          )}
          <p className="text-grey-700 mb-1 mt-4 text-base font-bold">
            {t(
              `modals:deleteUsersModal:allUserCount:${
                numberOfUsersToDelete === 1 ? 'single' : 'multi'
              }`,
              {
                numberOfUsersToDelete,
              },
            )}
          </p>
          {numberOfNonDevelopersToDelete > 0 && (
            <>
              <p className="text-grey-700 mb-1 mt-4 text-base font-bold">
                {t(
                  `modals:deleteUsersModal:nonDeveloperUserCount:${
                    numberOfNonDevelopersToDelete === 1 ? 'single' : 'multi'
                  }`,
                  { numberOfNonDevelopersToDelete },
                )}
              </p>
              {nonDevelopersToDelete.map((user) => (
                <React.Fragment key={user.id}>
                  <p className="text-grey-700 mb-1 text-base "> {user.name}</p>
                </React.Fragment>
              ))}
            </>
          )}
          {numberOfDevelopersToDelete > 0 && (
            <>
              <p className="text-grey-700 mb-1 mt-4 text-base font-bold">
                {t(
                  `modals:deleteUsersModal:developerUserCount:${
                    numberOfDevelopersToDelete === 1 ? 'single' : 'multi'
                  }`,
                  {
                    numberOfDevelopersToDelete,
                  },
                )}
              </p>
              <p className="text-grey-700 mb-4 text-base">
                {t('modals:deleteUsersModal:additionalDeveloperDescription')}
              </p>
              {areAvailableKeysLoading && <LoadingBounce />}
              {!areAvailableKeysLoading && (
                <ul>
                  {developersToDelete.filter(Boolean).map((dev) => (
                    <div
                      key={dev.id}
                      className="text-grey-700 mb-4 grid grid-flow-col grid-cols-2 items-center text-base"
                    >
                      <div>{dev.name}:</div>
                      <div className="w-250px col-start-2 grid">
                        {developerHasKeys(dev.id, apiKeys) ? (
                          <DropdownSingleSelect
                            onChange={(option) => {
                              if (
                                availableDevelopersForKeyTransfer.length === 1
                              )
                                return;
                              setKeyTransferPairs([
                                ...keyTransferPairs.filter(
                                  (item) => item.from !== dev.id,
                                ),
                                { from: dev.id, to: option.value.toString() },
                              ]);
                            }}
                            placeholder={t('placeholders:transferKeysTo')}
                            options={availableDevelopersForKeyTransfer}
                          />
                        ) : (
                          <div className="text-grey-600">
                            {t('modals:deleteUsersModal:noKeysToTransfer')}
                          </div>
                        )}
                      </div>

                      {keyTransferPairs.find((pair) => pair.from === dev.id)
                        ?.to === '0' && (
                        <div className="col-start-2 row-start-2 -ml-2 -mt-1 grid">
                          <Alert
                            message={t(
                              'modals:deleteUsersModal:deleteUserWithoutTransferringKeysAlert',
                            )}
                            flavour="infoNoBg"
                            icon="InfoCircle"
                            hasBorder={false}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </ul>
              )}
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
