import { SubFeatureType } from 'shared/types/features/FeatureIds';
import { Document, useDocument } from 'utils/firebase/hooks';

export type PerformanceTabConfigKeys =
  | SubFeatureType.PERFORMANCE_SALES
  | SubFeatureType.PERFORMANCE_OPPORTUNITIES
  | SubFeatureType.PERFORMANCE_LEADS
  | SubFeatureType.PERFORMANCE_PRODUCT_SAMPLES
  | SubFeatureType.PERFORMANCE_SUPPLY_CHAIN;

export type OpPerfTabConfigs = {
  [tabKey in PerformanceTabConfigKeys]: {
    title: string;
    reportPage: string;
    reportPage_customerData?: string;
    disableMarketsegments: boolean;
  };
};

export const useOpPerfTabConfigs = (): Document<OpPerfTabConfigs> => {
  return useDocument('configurations', 'operational_performance_tabs');
};
