import { IconName } from 'components/Icon';
import { AdminCenter } from 'features/AdminCenter/AdminCenter';
import { PrincipalAdministration } from 'features/AdminCenter/PrincipalAdministration/PrincipalAdministration';
import {
  APIKeyManagement,
  DataConnection,
  EDIInformation,
} from 'features/DataConnection';
import { APIDocumentation } from 'features/DataConnection/APIDocumentation';
import { DigitalActivities } from 'features/DigitalActivities/DigitalActivities';
import { GlobalUsers } from 'features/GlobalUsers';
import { OperationalPerformance } from 'features/OperationalPerformance';
import { MemberDetails } from 'features/PortalMembers/MemberDetails';
import { PortalMembers } from 'features/PortalMembers/PortalMembers';
import TermsOfUse from 'features/TermsOfUse/TermsOfUse';
import { compact } from 'lodash';
import React from 'react';
import { Permission } from 'shared/types/authorization';
import {
  MainFeatureType,
  SubFeatureType,
} from 'shared/types/features/FeatureIds';
import { Account } from '../../features/Account';
import CookieStatement from '../../features/CookieStatement/CookieStatement';
import { Dashboard } from '../../features/Dashboard';
import { MarketIntelligence } from '../../features/MarketIntelligence';
import { SharedDocuments } from '../../features/SharedDocuments';
import { SubFeature } from '../../hooks/usePrincipalFeatures';

export type MainMenuConfig = {
  section: 'primary' | 'secondary';
  order: number;
  title: string;
  icon: IconName;
};

export type MainFeatureComponent = React.FC<{
  subFeatures?: SubFeature[];
}>;

export type InternalRoute = {
  routeId?: FeatureType;
  url: string;
  name: string;
  Component: MainFeatureComponent;
  authRequired: boolean;
  permissions?: Permission[];
  mainMenuConfig?: MainMenuConfig;
  additionalInformation: {
    trackingCategory: 'Partner Area' | 'User Area' | 'Legal';
    trackingType:
      | 'Overview'
      | 'Report'
      | 'List'
      | 'Account Info'
      | 'Info'
      | 'Cookie Statement'
      | 'Portal Members'
      | 'Global Users'
      | 'Operational Performance'
      | 'Customer portal'
      | 'Data Connections'
      | 'API Key Management'
      | 'Terms Of Use'
      | 'EDI Information';
  };
};

export class InternalRouteCollection {
  constructor(public routes: InternalRoute[]) {}

  public find(id: MainFeatureType | SubFeatureType): InternalRoute {
    const route = this.routes.find(({ routeId }) => routeId === id);
    if (!route) {
      throw new Error(`Route ${id} not found`);
    }
    return route;
  }
}

export type FeatureType = MainFeatureType | SubFeatureType;

// This determines whether a route item should be in the main menu
// It also guarrantees to Typescript that these items WILL have mainMenuConfigs and permissions
export const hasMainMenuConfig = (
  i: InternalRoute,
): i is InternalRoute & {
  mainMenuConfig: MainMenuConfig;
} => !!i.mainMenuConfig;

export const encodePrincipalIdToUrl = (principalId?: string): string =>
  encodeURI(principalId?.toLowerCase() || '');

//selectedPrincipal can be undefined e.g. for cookie-statement route
export const routes = (selectedPrincipal?: string): InternalRouteCollection => {
  const selectedPrincipalUrl = selectedPrincipal ? '/' + selectedPrincipal : '';

  return new InternalRouteCollection([
    {
      routeId: MainFeatureType.DASHBOARD,
      url: `${selectedPrincipalUrl}/`,
      name: 'Dashboard',
      Component: Dashboard,
      authRequired: true,
      permissions: [Permission.DASHBOARD_VIEW],
      mainMenuConfig: {
        section: 'primary',
        order: 1,
        title: 'dashboard',
        icon: 'Home',
      },
      additionalInformation: {
        trackingCategory: 'Partner Area',
        trackingType: 'Overview',
      },
    },
    {
      routeId: MainFeatureType.MARKET_INTELLIGENCE,
      url: `${selectedPrincipalUrl}/market-intelligence`,
      name: 'Market Intelligence',
      Component: MarketIntelligence,
      authRequired: true,
      mainMenuConfig: {
        section: 'primary',
        order: 5,
        title: 'market-intelligence',
        icon: 'Market',
      },
      additionalInformation: {
        trackingCategory: 'Partner Area',
        trackingType: 'Report',
      },
    },
    {
      routeId: MainFeatureType.MARKET_INTELLIGENCE,
      url: `${selectedPrincipalUrl}/market-intelligence/:tab`,
      name: 'Market Intelligence',
      Component: MarketIntelligence,
      authRequired: true,
      additionalInformation: {
        trackingCategory: 'Partner Area',
        trackingType: 'Report',
      },
    },
    {
      routeId: MainFeatureType.SHARED_DOCUMENTS,
      url: `${selectedPrincipalUrl}/shared-documents`,
      name: 'Shared Documents',
      Component: SharedDocuments,
      authRequired: true,
      permissions: [Permission.SHARED_DOCUMENTS_VIEW],
      mainMenuConfig: {
        section: 'primary',
        order: 6,
        title: 'shared-documents',
        icon: 'File',
      },
      additionalInformation: {
        trackingCategory: 'Partner Area',
        trackingType: 'List',
      },
    },
    {
      routeId: MainFeatureType.ACCOUNT,
      url: `${selectedPrincipalUrl}/account`,
      name: 'User Overview',
      Component: Account,
      authRequired: true,
      permissions: [],
      mainMenuConfig: {
        section: 'secondary',
        order: 3,
        title: 'account',
        icon: 'Account',
      },
      additionalInformation: {
        trackingCategory: 'User Area',
        trackingType: 'Account Info',
      },
    },
    {
      routeId: MainFeatureType.COOKIE_STATEMENT,
      url: `${selectedPrincipalUrl}/cookie-statement`,
      name: 'Cookie Statement',
      Component: CookieStatement,
      authRequired: false,
      additionalInformation: {
        trackingCategory: 'User Area',
        trackingType: 'Cookie Statement',
      },
    },
    {
      routeId: MainFeatureType.TERMS_OF_USE,
      url: `${selectedPrincipalUrl}/terms-of-use`,
      name: 'Terms Of Use',
      Component: TermsOfUse,
      authRequired: false,
      additionalInformation: {
        trackingCategory: 'User Area',
        trackingType: 'Terms Of Use',
      },
    },
    {
      routeId: MainFeatureType.API_DOCUMENTATION,
      url: `${selectedPrincipalUrl}/api-documentation`,
      name: 'API Documentation',
      Component: APIDocumentation,
      authRequired: true,
      permissions: [Permission.DATA_CONNECTION_DEVELOPER_SCREENS],
      mainMenuConfig: {
        section: 'primary',
        order: 7,
        title: 'api-documentation',
        icon: 'Documentation',
      },
      additionalInformation: {
        trackingCategory: 'Partner Area',
        trackingType: 'List',
      },
    },
    {
      routeId: MainFeatureType.ADMIN_CENTER,
      url: `/admin-center`,
      name: 'Admin Center',
      Component: AdminCenter,
      authRequired: true,
      mainMenuConfig: {
        section: 'secondary',
        order: 1,
        title: 'admin-center',
        icon: 'Administration',
      },
      additionalInformation: {
        trackingCategory: 'User Area',
        trackingType: 'Portal Members',
      },
    },

    {
      routeId: SubFeatureType.PRINCIPAL_ADMINISTRATION,
      url: `/admin-center/principal-administration`,
      name: 'Principal Administration',
      Component: PrincipalAdministration,
      authRequired: true,
      additionalInformation: {
        trackingCategory: 'User Area',
        trackingType: 'Global Users',
      },
    },
    {
      routeId: SubFeatureType.GLOBAL_USERS,
      url: `/admin-center/global-users`,
      name: 'Global Users',
      Component: GlobalUsers,
      authRequired: true,
      permissions: [Permission.EDIT_GLOBAL_USERS],
      additionalInformation: {
        trackingCategory: 'User Area',
        trackingType: 'Portal Members',
      },
    },
    {
      routeId: MainFeatureType.PORTAL_MEMBERS,
      url: `${selectedPrincipalUrl}/portal-members`,
      name: 'Portal Members',
      Component: PortalMembers,
      authRequired: true,
      permissions: [
        Permission.USER_ROLE_MGMT_EDIT,
        Permission.USER_ROLE_MGMT_VIEW,
      ],
      mainMenuConfig: {
        section: 'secondary',
        order: 5,
        title: 'portal-members',
        icon: 'Settings',
      },
      additionalInformation: {
        trackingCategory: 'User Area',
        trackingType: 'Portal Members',
      },
    },
    {
      routeId: MainFeatureType.PERFORMANCE,
      url: `${selectedPrincipalUrl}/performance`,
      name: 'Performance',
      Component: OperationalPerformance,
      authRequired: true,
      mainMenuConfig: {
        section: 'primary',
        order: 2,
        title: 'performance',
        icon: 'Performance',
      },
      additionalInformation: {
        trackingCategory: 'Partner Area',
        trackingType: 'Operational Performance',
      },
    },
    {
      routeId: MainFeatureType.PERFORMANCE,
      url: `${selectedPrincipalUrl}/performance/:tab`,
      name: 'Performance',
      Component: OperationalPerformance,
      authRequired: true,
      permissions: [Permission.OPERATIONAL_PERFORMANCE_VIEW],
      additionalInformation: {
        trackingCategory: 'Partner Area',
        trackingType: 'Operational Performance',
      },
    },
    {
      routeId: MainFeatureType.PORTAL_MEMBERS,
      url: `${selectedPrincipalUrl}/portal-members/:uid`,
      name: 'Member details',
      Component: MemberDetails,
      authRequired: true,
      permissions: [
        Permission.USER_ROLE_MGMT_EDIT,
        Permission.USER_ROLE_MGMT_VIEW,
      ],
      additionalInformation: {
        trackingCategory: 'User Area',
        trackingType: 'Portal Members',
      },
    },
    {
      routeId: MainFeatureType.DIGITAL_ACTIVITIES,
      url: `${selectedPrincipalUrl}/digital-activities`,
      name: 'Digital Activities',
      Component: DigitalActivities,
      authRequired: true,
      permissions: [
        Permission.CUSTOMER_PORTAL_INSIGHTS_VIEW,
        Permission.SUPPLIER_PAGES_VIEW,
      ],
      mainMenuConfig: {
        section: 'primary',
        order: 4,
        title: 'digital-activities',
        icon: 'Promotion',
      },
      additionalInformation: {
        trackingCategory: 'Partner Area',
        trackingType: 'Report',
      },
    },
    {
      routeId: MainFeatureType.DIGITAL_ACTIVITIES,
      url: `${selectedPrincipalUrl}/digital-activities/:tab`,
      name: 'Digital Activities',
      Component: DigitalActivities,
      authRequired: true,
      permissions: [Permission.SHARED_DOCUMENTS_VIEW],
      additionalInformation: {
        trackingCategory: 'Partner Area',
        trackingType: 'Report',
      },
    },
    {
      routeId: MainFeatureType.DATA_CONNECTIONS,
      url: `${selectedPrincipalUrl}/data-connections`,
      name: 'Data Connections',
      Component: DataConnection,
      authRequired: true,
      permissions: [Permission.DASHBOARD_VIEW],
      mainMenuConfig: {
        section: 'primary',
        order: 3,
        title: 'data-connections',
        icon: 'DataConnection',
      },
      additionalInformation: {
        trackingCategory: 'User Area',
        trackingType: 'Data Connections',
      },
    },
    {
      routeId: SubFeatureType.DATA_CONNECTIONS_EDI_INFORMATION,
      url: `${selectedPrincipalUrl}/data-connections/edi-information`,
      name: 'EDI Information',
      Component: EDIInformation,
      authRequired: true,
      permissions: [Permission.DASHBOARD_VIEW],
      additionalInformation: {
        trackingCategory: 'User Area',
        trackingType: 'EDI Information',
      },
    },
    {
      routeId: SubFeatureType.DATA_CONNECTIONS_API_KEY_MANAGEMENT,
      url: `${selectedPrincipalUrl}/data-connections/api-key-management`,
      name: 'API Key Generator',
      Component: APIKeyManagement,
      authRequired: true,
      permissions: [Permission.DATA_CONNECTION_API_MANAGEMENT],
      additionalInformation: {
        trackingCategory: 'User Area',
        trackingType: 'EDI Information',
      },
    },
    {
      routeId: MainFeatureType.API_KEY_GENERATOR,
      url: `${selectedPrincipalUrl}/partner-area/api-key-generator`,
      name: 'Partner Area',
      Component: APIKeyManagement,
      authRequired: true,
      permissions: [Permission.DATA_CONNECTION_DEVELOPER_SCREENS],
      additionalInformation: {
        trackingCategory: 'Partner Area',
        trackingType: 'API Key Management',
      },
      mainMenuConfig: {
        section: 'primary',
        order: 7,
        title: 'partner-area',
        icon: 'Key',
      },
    },
  ]);
};

//All routes which should work without the url principal prefix
const testPattern = '_t1e2s3t4_';
export const ROUTES_WITHOUT_PRINCIPAL_PREFIX = compact(
  routes(testPattern).routes.map(
    (route) => !route.url.includes(testPattern) && route.url,
  ),
);

export const findRouteDefintionByPathname = (
  pathname: string,
  selectedPrincipal?: string,
): InternalRoute => {
  const routesValues = routes(selectedPrincipal).routes;

  for (const route of routesValues) {
    if (route.url === pathname) {
      return route;
    }
  }

  // return Dashboard as default Route

  return routes(selectedPrincipal).routes[0];
};
