import { CircleInitials } from 'components/CircleInitials';
import { useCurrentUser } from 'hooks/queries';
import { getCurrentPrincipal } from 'lib/currentPrincipal';
import { formatStandardDate } from 'lib/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../components/Heading';
import { usePrincipals, useUnits } from '../../hooks/queries';

export const Account: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(user.id, principals);
  const { unitDefinitions } = useUnits();

  // const [showEditPreferencesModal, setShowEditPreferencesModal] =
  //   useState(false);

  // const onChangeUserPreferences = (newUserUnits: UserUnits) => {
  //   update({
  //     units: {
  //       ...user?.units,
  //       [user?.selectedPrincipal || '']: {
  //         ...newUserUnits,
  //       },
  //     },
  //   })?.then(() => {
  //     setShowEditPreferencesModal(false);
  //   });
  // };

  return (
    <div className="text-grey-700 font-regular w-full px-4 font-sans md:px-6">
      <div className="mb-7 mt-6 md:mt-7">
        <Heading text={t('features:account:heading')} level="h1" />
      </div>

      <div className="flex justify-between">
        <div className="mb-4 flex flex-row md:mb-4">
          <CircleInitials name={user.name} />
          <Heading text={user.name} level="h2" />
        </div>
        {/* <div>
          <Button
            buttonType={ButtonType.WHITE}
            label={t('linksOrButtons:edit')}
            onClick={() => setShowEditPreferencesModal(true)}
          />
        </div> */}
      </div>

      <div className="mb-6">
        <Heading
          text={t('features:account:subheadings:generalInformation')}
          level="h3"
        />
        <div className="max-w-730px flex flex-row flex-wrap justify-between">
          <div>
            <Heading text={t('features:account:labels:email')} level="h6" />
            <div>{user.email}</div>
          </div>
          <div>
            <Heading
              text={t('features:account:labels:memberSince')}
              level="h6"
            />
            <div>{formatStandardDate(user.creationTime)}</div>
          </div>
          <div>
            <Heading text={t('features:account:labels:company')} level="h6" />
            <div>{currentPrincipal.name}</div>
          </div>
          {principals && principals.length > 1 && (
            <div className="pt-4">
              <Heading
                text={t('features:account:labels:principals')}
                level="h6"
              />
              <div className="max-w-400px flex flex-row flex-wrap">
                {principals.map(({ name }) => name).join(', ')}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="border-grey-500 hidden border-t pt-6">
        <Heading
          text={t('features:account:subheadings:preferences', {
            principal: currentPrincipal.name,
          })}
          level="h3"
        />
        <div className="max-w-400px flex flex-row justify-between">
          <div>
            <Heading text={t('features:account:labels:currency')} level="h6" />
            <div>
              {
                unitDefinitions?.currency?.[
                  user?.units?.[currentPrincipal.id]?.currency || ''
                ]
              }
            </div>
          </div>
          <div>
            <Heading text={t('features:account:labels:weight')} level="h6" />
            <div>
              {
                unitDefinitions?.weight?.[
                  user?.units?.[currentPrincipal.id]?.weight || ''
                ]
              }
            </div>
          </div>
        </div>
      </div>
      {/* {showEditPreferencesModal && user?.units && (
        <EditPreferencesModal
          userUnits={user.units[user.selectedPrincipal]}
          onCancel={() => setShowEditPreferencesModal(false)}
          onConfirm={onChangeUserPreferences}
        />
      )} */}
    </div>
  );
};
