import { Button, ButtonType } from 'components/Button';
import { CircleInitials } from 'components/CircleInitials';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { Link } from 'components/Link';
import { Loading, LoadingBounce } from 'components/Loading';
import { MandateSelection } from 'components/MandateSelection';
import { PermissionDenied } from 'components/PermissionDenied/PermissionDenied';
import {
  EditUserDetailsProps,
  EditUserModal,
  SavingModal,
} from 'components/UserManagementModals';
import { routes } from 'controllers/ContentController/Routes';
import { DeleteUsersModal } from 'features/DeleteUsersModal/DeleteUsersModal';
import {
  useCRMMandates,
  useCurrentUser,
  usePrincipals,
  useUnits,
  useUser,
} from 'hooks/queries';
import { usePermission } from 'hooks/usePermission';
import { getCurrentPrincipal } from 'lib/currentPrincipal';
import { formatStandardDate } from 'lib/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Permission, Role } from 'shared/types/authorization';
import { MainFeatureType } from 'shared/types/features/FeatureIds';
import { calculateMandatesPerUser } from 'utils/firebase/cloud-functions';
import { UserData } from 'utils/firebase/collection/firebase-collection-types';
import { MemberDetailsDataBlock } from './MemberDetailsDataBlock';

type ExistingUserDataProcessorProps = {
  editedData: EditUserDetailsProps;
  oldEmailAddress?: string;
};

export const environmentCheck = (env: string | undefined): string | undefined =>
  env === 'local' ? undefined : 'europe-west1';

export const userDisplayDataHandler = (
  prev: UserData | undefined,
  editedData: EditUserDetailsProps,
): UserData | undefined => {
  return {
    ...prev,
    email: editedData.email,
    name: editedData.name,
    roles: { ...editedData.roles },
    units: editedData.units,
  } as UserData;
};

export const existingUserDataProcessor = (
  editedData: EditUserDetailsProps,
  userToDisplay: UserData | undefined,
): ExistingUserDataProcessorProps => ({
  editedData,
  oldEmailAddress: userToDisplay?.email,
});

export const MemberDetails: React.FC = () => {
  const navigate = useNavigate();
  const { uid } = useParams<{ uid: string }>();
  const { user: currentUser } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(currentUser.id, principals);
  const portalMembersRoute = routes(currentPrincipal.slug).find(
    MainFeatureType.PORTAL_MEMBERS,
  );

  if (!uid) {
    navigate(portalMembersRoute.url);
    throw Error('Invalid user id. Redirecting to portal members page ...');
  }

  const user = useUser(uid);

  const { unitDefinitions } = useUnits();
  const { userHasPermission } = usePermission();
  const userCanEditMembers = userHasPermission(Permission.USER_ROLE_MGMT_EDIT);
  const userCanViewMembers = userHasPermission(Permission.USER_ROLE_MGMT_VIEW);
  const { t } = useTranslation();
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [showEditUserIsProcessing, setShowEditUserIsProcessing] =
    useState(false);
  const { data: mandates } = useCRMMandates({
    principalPri: currentPrincipal.id,
  });

  if (!user)
    return (
      <Loading className="flex w-full items-center justify-center" loading />
    );

  const {
    user: displayedUser,
    update: updateDisplayedUser,
    role,
    userType,
    isGlobalUser: displayedUserIsGlobalUser,
  } = user;

  const processExistingUser = async (editedData: Partial<UserData>) => {
    const dataToSend = {
      principalId: currentPrincipal.id,
      selectedMandatesFilter: editedData.mandateFilters?.[currentPrincipal.id],
      email: editedData.email,
      userId: uid,
    };
    setShowEditUserIsProcessing(true);

    await calculateMandatesPerUser(dataToSend).catch(console.error);

    updateDisplayedUser(editedData)
      ?.then(() => {
        setShowEditUserIsProcessing(false);
        setShowEditUserModal(false);
      })
      .catch(console.error);
  };

  const showHasAllMandatesDescription: boolean =
    (displayedUser?.mandateFilters?.[currentPrincipal.name]?.hasAllMandates ??
      false) &&
    displayedUserIsGlobalUser;

  const isGlobalUserOrCurrentUser =
    uid === currentUser?.id || user.isGlobalUser;

  return (
    <div className="w-full">
      {userCanEditMembers || userCanViewMembers ? (
        <>
          <div className="w-full px-4 md:px-6">
            {displayedUser && currentUser ? (
              <div className="max-w-xl-content mx-auto">
                <div className="mb-0 pt-6 md:mb-0 md:pt-7">
                  <div className="mb-3">
                    <Link
                      label={t('linksOrButtons:backToPortalMembers')}
                      icon="ArrowLeft"
                      iconPosition="left"
                      onClick={() => navigate(portalMembersRoute.url)}
                    />
                  </div>
                  <div className="align-center flex justify-between">
                    <div className="flex">
                      <CircleInitials name={displayedUser?.name || ''} />
                      <Heading
                        text={
                          displayedUser?.name ||
                          t('features:portal-members:labels:unknownName')
                        }
                        margin={0}
                        level="h1"
                      />
                    </div>
                    <div className="flex items-center space-x-4">
                      {!isGlobalUserOrCurrentUser && userCanEditMembers && (
                        <Link
                          label={t(
                            'features:member-details:buttons:deleteUser',
                          )}
                          icon="Delete"
                          iconClassName="w-20px h-20px mr-1.5"
                          buttonClassName="font-medium"
                          onClick={() => setShowDeleteUserModal(true)}
                        />
                      )}
                      {userCanEditMembers && (
                        <Button
                          buttonType={ButtonType.WHITE}
                          className="font-medium"
                          label={t('features:member-details:buttons:editUser')}
                          onClick={() => setShowEditUserModal(true)}
                        />
                      )}
                    </div>
                  </div>
                  <h3 className="text-grey-700 mt-5 text-2xl font-medium">
                    {t('features:member-details:generalUserInformation')}
                  </h3>
                  <div className="text-grey-700 max-w-grid mt-4 flex flex-col justify-between md:flex-row">
                    {[
                      {
                        label: t('features:member-details:labels:email'),
                        data: displayedUser.email?.toLowerCase(),
                      },
                      {
                        label: t('features:member-details:labels:memberSince'),
                        data: formatStandardDate(displayedUser.creationTime),
                      },
                      {
                        label: t('features:member-details:labels:userType'),
                        data: displayedUser.email?.toLowerCase()
                          ? t(
                              `features:portal-members:labels:userTypes:${userType}`,
                            )
                          : undefined,
                      },
                      {
                        label: t('features:member-details:labels:role'),
                        data: t(
                          `features:portal-members:labels:roles:${
                            role === Role.GLOBAL_USER_EDIT
                              ? Role.GLOBAL_USER_EDIT
                              : role
                          }`,
                        ),
                      },
                      {
                        label: t(
                          'features:member-details:labels:activeMandates',
                        ),
                        data: user.isGlobalUser
                          ? mandates?.count.mandates
                          : displayedUser?.assignedMandates?.[
                              currentPrincipal.id
                            ] || 0,
                      },
                    ].map(({ label, data }) => (
                      <MemberDetailsDataBlock
                        key={label}
                        label={label}
                        data={data}
                      />
                    ))}
                  </div>
                </div>
                <div className="hidden">
                  <h3 className="text-grey-700 mb-4 text-2xl font-medium">
                    {t('features:member-details:userPreferences')}
                  </h3>
                  <div className="text-grey-700 max-w-grid mt-5 flex flex-col md:flex-row md:space-x-10">
                    {[
                      {
                        label: t('features:member-details:labels:currency'),
                        data:
                          unitDefinitions?.currency?.[
                            displayedUser?.units?.[currentPrincipal.id]
                              ?.currency || ''
                          ] || '',
                      },
                      {
                        label: t('features:member-details:labels:weight'),
                        data:
                          unitDefinitions?.weight?.[
                            displayedUser?.units?.[currentPrincipal.id]
                              ?.weight || ''
                          ] || '',
                      },
                    ].map(({ label, data }) => (
                      <MemberDetailsDataBlock
                        key={label}
                        label={label}
                        data={data}
                      />
                    ))}
                  </div>
                </div>
                <div className="w-full">
                  <div className="my-6">
                    <div className="h-1px border-grey-500 bg-grey-500 border-0 border-b-0 " />
                  </div>
                </div>

                <div className="text-grey-600 mb-1 grid grid-flow-row grid-cols-1">
                  <h3 className="text-grey-700 items-center text-2xl font-medium">
                    {t('features:member-details:userMandates')}
                  </h3>
                  <div className="mb-3 mt-2 inline-flex flex-row items-center justify-end">
                    <div>
                      {showHasAllMandatesDescription && (
                        <div className="text-2xs flex justify-end">
                          {t('modals:addUserModal:hasAllMandatesDescription')}
                        </div>
                      )}
                      <div className="text-2xs flex justify-end">
                        {displayedUserIsGlobalUser
                          ? t('modals:addUserModal:editUserIsGlobalUser')
                          : t('modals:addUserModal:editMandateDescription')}
                      </div>
                    </div>

                    <div className="text-text2 flex justify-end">
                      <span className="ml-1 w-4">
                        <Icon name="InfoCircle" />
                      </span>
                    </div>
                  </div>
                </div>

                <MandateSelection
                  mandates={mandates}
                  initMandateFilters={displayedUser?.mandateFilters}
                  selectedPrincipal={currentPrincipal}
                  isGlobalUser={user.isGlobalUser}
                  disabled
                />
                {
                  // Depend on showEditUserModal to force re-render and clear inputs
                  showEditUserModal && (
                    <EditUserModal
                      userType={userType}
                      onCancel={() => setShowEditUserModal(false)}
                      onConfirm={processExistingUser}
                      userData={user}
                    />
                  )
                }

                {showEditUserIsProcessing && (
                  <SavingModal
                    topMessage={t('modals:editUserModal:savingMandateMessage')}
                    bottomMessage={t('modals:editUserModal:waitingMessage')}
                  />
                )}

                {showDeleteUserModal && (
                  <DeleteUsersModal
                    usersToDelete={[
                      {
                        id: uid,
                        name: displayedUser.name ?? '',
                        role: displayedUser.roles[currentPrincipal.id],
                      },
                    ]}
                    selectedPrincipal={currentPrincipal}
                    onCancel={() => setShowDeleteUserModal(false)}
                  />
                )}
              </div>
            ) : (
              <LoadingBounce text={t('labels:loading')} />
            )}
          </div>
        </>
      ) : (
        <PermissionDenied />
      )}
    </div>
  );
};
