import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { getFirebaseBearerToken } from 'lib/getFirebaseBearerToken';

export interface SubFeature {
  id: string;
  maintenanceMode: boolean;
}

export interface MainFeature {
  id: string;
  isMaintenance: boolean;
  subFeatures?: SubFeature[];
}

export const usePrincipalFeatures = (
  principalId: string,
): UseQueryResult<MainFeature[]> =>
  useQuery({
    queryFn: async () => {
      return await axios({
        url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/principals/${principalId}/features`,
        method: 'Get',
        headers: {
          Authorization: `Bearer ${await getFirebaseBearerToken()}`,
        },
      });
    },

    queryKey: ['principalFeatureAccessRights'],
    select: ({ data }) => data.features,
  });
