import { Alert } from 'components/Alert';
import { DropdownSingleSelect } from 'components/Dropdown/DropdownVariants/DropdownSingleSelect';
import { Icon } from 'components/Icon';
import { Input } from 'components/Input';
import { MandateSelection } from 'components/MandateSelection';
import {
  MandateSelectionFilterProps,
  SelectedMandates,
} from 'components/MandateSelection/MandateSelection';
import { Modal } from 'components/Modal';
import {
  RoleSelector,
  RoleSelectorFilter,
} from 'components/RoleSelector/RoleSelector';
import { ToggleSwitch } from 'components/ToggleSwitch';
import {
  useCurrentUser,
  usePrincipals,
  useUnits,
  UseUserResult,
} from 'hooks/queries';
import { useCRMMandates } from 'hooks/queries/useCRMMandates';
import { getCurrentPrincipal } from 'lib/currentPrincipal';
import { isObjectValuesFilled } from 'lib/utils';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Role, UserType } from 'shared/types/authorization';
import {
  UserData,
  UserUnits,
} from 'utils/firebase/collection/firebase-collection-types';

type EditUserModalProps = {
  userType: UserType | undefined;
  onCancel: () => void;
  onConfirm: (newRole: Partial<UserData>) => void;
  userData: UseUserResult;
};

export type EditUserDetailsProps = {
  name: string | undefined;
  email: string | undefined;
  roles: { [key: string]: Role };
  units: {
    [key: string]: UserUnits;
  };
  mandateFilters?: MandateSelectionFilterProps;
};

type ChangeFormValueType = Record<
  string,
  | Record<string, string | string[] | Record<string, string[] | string>>
  | string
  | MandateSelectionFilterProps
>;

export const EditUserModal: React.FC<EditUserModalProps> = ({
  onCancel,
  onConfirm,
  userData,
}: EditUserModalProps) => {
  const { t } = useTranslation();
  const { user, role } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(user.id, principals);
  const { unitDefinitions, weight, currency } = useUnits();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [editingUser, setEditingUser] = useState<UseUserResult>();
  const [selectedRole, setSelectedRole] = useState<Role>(
    userData.user.roles[currentPrincipal.id],
  );

  // We're using Firebase Real-time updates and in the edit modal that behaviour can be very unwanted
  const { user: toEditUser, isGlobalUser: toEditUserIsGlobalUser } =
    useMemo(() => {
      if (!editingUser) {
        setEditingUser(userData);

        return userData;
      }
      return editingUser;
    }, [editingUser, userData]);

  const initUserDetails: EditUserDetailsProps = {
    name: toEditUser?.name,
    email: toEditUser?.email,
    roles: { ...toEditUser?.roles },
    units: {
      ...toEditUser?.units,
    },
    mandateFilters: toEditUser?.mandateFilters,
  };

  const [existingUserDetails, setExistingUserDetails] =
    useState(initUserDetails);

  const userFilters =
    existingUserDetails?.mandateFilters?.[currentPrincipal.id];

  const { data: mandates } = useCRMMandates({
    principalPri: currentPrincipal.id,
  });

  const { data: filteredMandates } = useCRMMandates({
    principalPri: currentPrincipal.id,
    ...(userFilters && {
      filter: {
        hasAllMandates: userFilters?.hasAllMandates,
        iso: userFilters?.country,
        marketSegment: userFilters?.marketSegment,
        portfolio: userFilters?.portfolio,
        territory: userFilters?.territory,
      },
    }),
  });

  const availableUsaTerritories = filteredMandates?.territories ?? [];

  const hasAllMandates: boolean | undefined =
    toEditUser?.mandateFilters?.[currentPrincipal.id]?.hasAllMandates;

  const filteredMandatesCount = filteredMandates?.count.mandates ?? 0;

  const onChangeForm = useCallback(
    (inputValue: ChangeFormValueType) => {
      setIsProcessing(false);
      setExistingUserDetails({ ...existingUserDetails, ...inputValue });
    },
    [existingUserDetails],
  );

  const availableMarketSegments =
    mandates?.marketSegments.map(({ id }) => id) ?? [];

  const availablePortfolios = mandates?.portfolios.map(({ id }) => id) ?? [];

  const availableCountries = mandates?.countries.map(({ iso }) => iso) ?? [];

  const setUserHasAllMandates = (isSelected: boolean) => {
    if (!toEditUser) return console.log('user does not exist');

    const oldFilters = toEditUser.mandateFilters;

    onChangeForm({
      mandateFilters: {
        ...oldFilters,
        [currentPrincipal.id]: {
          hasAllMandates: isSelected,
          portfolio: isSelected
            ? availablePortfolios
            : oldFilters?.[currentPrincipal.id].portfolio ?? [],
          country: isSelected
            ? availableCountries
            : oldFilters?.[currentPrincipal.id].country ?? [],
          marketSegment: isSelected
            ? availableMarketSegments
            : oldFilters?.[currentPrincipal.id].marketSegment ?? [],
          territory: isSelected
            ? availableUsaTerritories
            : oldFilters?.[currentPrincipal.id].territory ?? [],
        },
      },
    });
  };

  let roleSelectorFilter: RoleSelectorFilter | undefined = undefined;
  if (toEditUserIsGlobalUser)
    roleSelectorFilter = ([key]) => key === selectedRole;

  const onConfirmHandler = useCallback(() => {
    const data: Partial<UserData> = { ...existingUserDetails };
    if (selectedRole) {
      data.roles = {
        ...toEditUser.roles,
        [currentPrincipal.id]: selectedRole,
      };
    }

    onConfirm(data);
    setIsProcessing(true);
  }, [
    currentPrincipal.id,
    existingUserDetails,
    onConfirm,
    selectedRole,
    toEditUser.roles,
  ]);

  const handleMandateFilterChange = useCallback(
    (mandateFiltersItems: SelectedMandates) => {
      const oldFilters = toEditUser.mandateFilters;

      onChangeForm({
        mandateFilters: {
          ...oldFilters,
          [currentPrincipal.id]: mandateFiltersItems,
        },
      });
    },
    [toEditUser.mandateFilters, onChangeForm, currentPrincipal.id],
  );

  const onRoleSelectorChangeHandler = (option: string) => {
    setSelectedRole(option as Role);
  };

  return (
    <Modal
      isFullscreen={true}
      isBig={true}
      heading={t('modals:editUserModal:heading')}
      headingAlign="left"
      confirmText={t('modals:editUserModal:confirm')}
      onCancel={onCancel}
      isConfirmButtonDisabled={
        !isObjectValuesFilled(existingUserDetails) || isProcessing
      }
      onConfirm={onConfirmHandler}
    >
      <div className="text-grey-700">
        {!userData.isGlobalUser && (
          <p className="mb-4 mt-1">{t('modals:editUserModal:description')}</p>
        )}
        <div className="mb-4">
          <Alert
            message={t('modals:editUserModal:errors:alertADMatch')}
            flavour="error"
            icon="Error"
          />
        </div>

        <h3 className="text-grey-700 mb-1 items-center text-2xl font-medium">
          {t('features:member-details:generalUserInformation')}
        </h3>

        <fieldset>
          <div className="sm:w-10vw lg:w-50vw">
            <label
              htmlFor="existingUser_firstName"
              className="mb-1 block font-medium"
            >
              {t('modals:editUserModal:name')}
            </label>

            <Input
              id="existingUser_name"
              dataTestid="existingUser_name"
              placeholder="Alfonso"
              initialValue={toEditUser?.name}
              onChange={(e) => {
                onChangeForm({ name: e.target.value });
              }}
            />
          </div>
        </fieldset>
        <fieldset>
          <div className="sm:w-10vw lg:w-50vw my-4">
            <label
              htmlFor="existingUser_email"
              className="mb-1 block font-medium"
            >
              {t('modals:editUserModal:email')}
            </label>
            <Input
              initialValue={toEditUser?.email}
              id="existingUser_email"
              placeholder="email@domain.eu"
              type="email"
              disabled
            />
          </div>
        </fieldset>
        <fieldset className="sm:w-10vw lg:w-50vw">
          <label className="mb-1 block font-medium">
            {t('modals:editUserModal:role')}
          </label>
          <RoleSelector
            disabled={toEditUserIsGlobalUser}
            filter={roleSelectorFilter}
            onChange={onRoleSelectorChangeHandler}
            selected={selectedRole}
            userRole={role}
            userType={userData?.userType}
          />
        </fieldset>

        <h3 className="text-grey-700 mb-3 mt-4 hidden items-center text-2xl font-medium">
          {t('modals:editUserModal:userPreferences')}
        </h3>

        <div className="sm:w-10vw lg:w-50vw hidden grid-cols-2 gap-4">
          <div>
            <label className="mb-1 block font-medium">
              {t('modals:editUserModal:currency')}
            </label>
            <DropdownSingleSelect
              options={currency}
              initialSelection={{
                value: existingUserDetails.units[currentPrincipal.id]?.currency,
                name:
                  unitDefinitions?.currency?.[
                    existingUserDetails.units[currentPrincipal.id]?.currency
                  ] || '',
              }}
              onChange={(option) => {
                existingUserDetails.units[currentPrincipal.id]?.currency !==
                  option?.value &&
                  onChangeForm({
                    units: {
                      ...existingUserDetails?.units,
                      [currentPrincipal.id]: {
                        ...existingUserDetails?.units[currentPrincipal.id],
                        currency: option?.value.toString() || '',
                      },
                    },
                  });
              }}
            />
          </div>
          <div>
            <label className="mb-1 block font-medium">
              {t(`modals:editUserModal:weight`)}
            </label>
            <DropdownSingleSelect
              options={weight}
              initialSelection={{
                value: existingUserDetails.units[currentPrincipal.id]?.weight,
                name:
                  unitDefinitions?.weight?.[
                    existingUserDetails.units[currentPrincipal.id]?.weight
                  ] || '',
              }}
              onChange={(option) => {
                existingUserDetails.units[currentPrincipal.id]?.weight !==
                  option?.value &&
                  onChangeForm({
                    units: {
                      ...existingUserDetails?.units,
                      [currentPrincipal.id]: {
                        ...existingUserDetails?.units[currentPrincipal.id],
                        weight: option?.value.toString() || '',
                      },
                    },
                  });
              }}
            />
          </div>
        </div>

        <div className="mb-1 mt-9 grid grid-flow-row grid-cols-2 justify-end">
          <div className="text-grey-600 mb-1 grid grid-flow-row grid-cols-1 ">
            <div className="mr-1 inline-flex flex-row items-center justify-start">
              <h3 className="text-grey-700 items-center text-2xl font-medium">
                {t('features:member-details:userMandates')}
              </h3>

              <div className="text-text2 flex justify-start">
                <span className="w-16px ml-1 w-4">
                  <Icon name="InfoCircle" />
                </span>
              </div>
            </div>
            <div className="text-s">
              {t(
                `modals:addUserModal:numberOfSelectedMandates_${
                  filteredMandatesCount === 1 ? 'one' : 'other'
                }`,
                {
                  count: userData.isGlobalUser
                    ? mandates?.count.mandates ?? 0
                    : filteredMandatesCount,
                },
              )}
            </div>
          </div>
          <div className="text-grey-600 mb-1 grid grid-flow-row grid-cols-1 justify-end">
            <div className="flex justify-end">
              {!toEditUserIsGlobalUser && (
                <ToggleSwitch
                  initialChecked={hasAllMandates}
                  label={t('modals:addUserModal:toggleLabel')}
                  onChange={setUserHasAllMandates}
                />
              )}
            </div>
            <div className="mb-3 mr-1 mt-1 inline-flex flex-row items-center justify-end">
              <div className="text-2xs">
                {toEditUserIsGlobalUser
                  ? t('modals:addUserModal:editUserIsGlobalUser')
                  : t('modals:addUserModal:editHasAllMandatesDescription')}
              </div>

              <div className="text-text2 flex justify-end">
                <span className="ml-1 w-4">
                  <Icon name="InfoCircle" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <MandateSelection
          mandates={mandates}
          handleMandateFilterChange={handleMandateFilterChange}
          initMandateFilters={existingUserDetails?.mandateFilters}
          selectedPrincipal={currentPrincipal}
          isGlobalUser={userData.isGlobalUser}
        />
        <div className="sm:w-10vw lg:w-50vw my-4">
          <Alert
            message={t('modals:editUserModal:savingMandateAlert')}
            flavour="info"
            icon="Warning"
          />
        </div>
      </div>
    </Modal>
  );
};
