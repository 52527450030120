import React, { useMemo } from 'react';
import { ReactComponent as AccountSettings } from '../../icons/content/account-settings.svg';
import { ReactComponent as Activation } from '../../icons/content/activation.svg';
import { ReactComponent as Administration } from '../../icons/content/administration.svg';
import { ReactComponent as AnimalNutrition } from '../../icons/content/animal-nutrition.svg';
import { ReactComponent as Case } from '../../icons/content/case.svg';
import { ReactComponent as CheckmarkBig } from '../../icons/content/checkmark-big.svg';
import { ReactComponent as Connection } from '../../icons/content/connection.svg';
import { ReactComponent as EDIInfo } from '../../icons/content/edi-info.svg';
import { ReactComponent as Electronics } from '../../icons/content/electronics.svg';
import { ReactComponent as EmptyMarket } from '../../icons/content/empty-market.svg';
import { ReactComponent as Empty } from '../../icons/content/empty.svg';
import { ReactComponent as EnhanceFormulation } from '../../icons/content/enhance-formulation.svg';
import { ReactComponent as ContentError } from '../../icons/content/error.svg';
import { ReactComponent as ErrorPage } from '../../icons/content/errorpage.svg';
import { ReactComponent as EssentialChemicals } from '../../icons/content/essential-chemicals.svg';
import { ReactComponent as FineChemicals } from '../../icons/content/fine-chemicals.svg';
import { ReactComponent as FixFormulation } from '../../icons/content/fix-formulation.svg';
import { ReactComponent as FoodAndHealth } from '../../icons/content/food-and-health.svg';
import { ReactComponent as Global } from '../../icons/content/global.svg';
import { ReactComponent as HomecareAndIndustrialCleaning } from '../../icons/content/homecare-and-industrial-cleaning.svg';
import { ReactComponent as LubricantsAndMetalWorkingFluids } from '../../icons/content/lubricants-and-metal-working-fluids.svg';
import { ReactComponent as UnderMaintenance } from '../../icons/content/maintenance.svg';
import { ReactComponent as OrderHistory } from '../../icons/content/order-history.svg';
import { ReactComponent as OtherApplications } from '../../icons/content/other-applications.svg';
import { ReactComponent as PersonalCare } from '../../icons/content/personal-care.svg';
import { ReactComponent as Pharma } from '../../icons/content/pharma.svg';
import { ReactComponent as PrincipalAdministration } from '../../icons/content/principal-administration.svg';
import { ReactComponent as RubberAndPlasticAdditives } from '../../icons/content/rubber-and-plastic-additives.svg';
import { ReactComponent as SendBriefing } from '../../icons/content/send-briefing.svg';
import { ReactComponent as SpecialityAgriHorti } from '../../icons/content/speciality-agri-horti.svg';
import { ReactComponent as Support } from '../../icons/content/support.svg';
import { ReactComponent as TextilesLeatherAndPaper } from '../../icons/content/textiles-leather-and-paper.svg';
import { ReactComponent as AnimalNutritionSolid } from '../../icons/markets/animal-nutrition.svg';
import { ReactComponent as CaseSolid } from '../../icons/markets/case.svg';
import { ReactComponent as ElectronicsSolid } from '../../icons/markets/electronics.svg';
import { ReactComponent as EssentialChemicalsSolid } from '../../icons/markets/essential-chemicals.svg';
import { ReactComponent as FineChemicalsSolid } from '../../icons/markets/fine-chemicals.svg';
import { ReactComponent as FoodAndHealthSolid } from '../../icons/markets/food-and-health.svg';
import { ReactComponent as GlobalSolid } from '../../icons/markets/global.svg';
import { ReactComponent as HomecareAndIndustrialCleaningSolid } from '../../icons/markets/homecare-and-industrial-cleaning.svg';
import { ReactComponent as LubricantsAndMetalWorkingFluidsSolid } from '../../icons/markets/lubricants-and-metal-working-fluids.svg';
import { ReactComponent as OtherApplicationsSolid } from '../../icons/markets/other-applications.svg';
import { ReactComponent as PersonalCareSolid } from '../../icons/markets/personal-care.svg';
import { ReactComponent as PharmaSolid } from '../../icons/markets/pharma.svg';
import { ReactComponent as RubberAndPlasticAdditivesSolid } from '../../icons/markets/rubber-and-plastic-additives.svg';
import { ReactComponent as SpecialityAgriHortiSolid } from '../../icons/markets/speciality-agri-horti.svg';
import { ReactComponent as TextilesLeatherAndPaperSolid } from '../../icons/markets/textiles-leather-and-paper.svg';
import { ReactComponent as AccountBehalf } from '../../icons/system/account-behalf.svg';
import { ReactComponent as Account } from '../../icons/system/account.svg';
import { ReactComponent as Add } from '../../icons/system/add.svg';
import { ReactComponent as AdminPage } from '../../icons/system/admin_page.svg';
import { ReactComponent as ArrowDown } from '../../icons/system/arrow-down.svg';
import { ReactComponent as ArrowLeft } from '../../icons/system/arrow-left.svg';
import { ReactComponent as ArrowNavDown } from '../../icons/system/arrow-nav-down.svg';
import { ReactComponent as ArrowNavUp } from '../../icons/system/arrow-nav-up.svg';
import { ReactComponent as ArrowRight } from '../../icons/system/arrow-right.svg';
import { ReactComponent as ArrowUp } from '../../icons/system/arrow-up.svg';
import { ReactComponent as AttentionSign } from '../../icons/system/attention-sign.svg';
import { ReactComponent as Cancel } from '../../icons/system/cancel.svg';
import { ReactComponent as Chat } from '../../icons/system/chat.svg';
import { ReactComponent as CheckFilled } from '../../icons/system/check-filled.svg';
import { ReactComponent as Checkmark } from '../../icons/system/checkmark.svg';
import { ReactComponent as Close } from '../../icons/system/close.svg';
import { ReactComponent as Copy } from '../../icons/system/copy.svg';
import { ReactComponent as DataConnection } from '../../icons/system/data-connection.svg';
import { ReactComponent as Delete } from '../../icons/system/delete.svg';
import { ReactComponent as Documentation } from '../../icons/system/documentation.svg';
import { ReactComponent as Download } from '../../icons/system/download.svg';
import { ReactComponent as Edit } from '../../icons/system/edit.svg';
import { ReactComponent as Error } from '../../icons/system/error.svg';
import { ReactComponent as FileAdd } from '../../icons/system/file-add.svg';
import { ReactComponent as File } from '../../icons/system/file.svg';
import { ReactComponent as FileDoc } from '../../icons/system/file_DOC.svg';
import { ReactComponent as FileImage } from '../../icons/system/file_IMAGE.svg';
import { ReactComponent as FilePDF } from '../../icons/system/file_PDF.svg';
import { ReactComponent as FilePPT } from '../../icons/system/file_PPT.svg';
import { ReactComponent as FileXLS } from '../../icons/system/file_XLS.svg';
import { ReactComponent as Files } from '../../icons/system/files.svg';
import { ReactComponent as Folder } from '../../icons/system/folder.svg';
import { ReactComponent as GlobalUser } from '../../icons/system/global-user.svg';
import { ReactComponent as Home } from '../../icons/system/home.svg';
import { ReactComponent as InfoCircle } from '../../icons/system/info-circle.svg';
import { ReactComponent as Key } from '../../icons/system/key.svg';
import { ReactComponent as ExternalLink } from '../../icons/system/link-external.svg';
import { ReactComponent as Location } from '../../icons/system/location.svg';
import { ReactComponent as Mail } from '../../icons/system/mail.svg';
import { ReactComponent as Market } from '../../icons/system/market.svg';
import { ReactComponent as Menu } from '../../icons/system/menu.svg';
import { ReactComponent as None } from '../../icons/system/none.svg';
import { ReactComponent as Performance } from '../../icons/system/performance.svg';
import { ReactComponent as Phone } from '../../icons/system/phone.svg';
import { ReactComponent as Premium } from '../../icons/system/premium.svg';
import { ReactComponent as Promotion } from '../../icons/system/promotion.svg';
import { ReactComponent as Remove } from '../../icons/system/remove.svg';
import { ReactComponent as Reset } from '../../icons/system/reset.svg';
import { ReactComponent as Restore } from '../../icons/system/restore.svg';
import { ReactComponent as Sales } from '../../icons/system/sales.svg';
import { ReactComponent as Save } from '../../icons/system/save.svg';
import { ReactComponent as Search } from '../../icons/system/search.svg';
import { ReactComponent as Settings } from '../../icons/system/settings.svg';
import { ReactComponent as Share } from '../../icons/system/share.svg';
import { ReactComponent as SignIn } from '../../icons/system/sign-in.svg';
import { ReactComponent as SignOut } from '../../icons/system/sign-out.svg';
import { ReactComponent as Tiles } from '../../icons/system/tiles.svg';
import { ReactComponent as Warning } from '../../icons/system/warning.svg';
import './Icon.css';

export const systemIcons: { [key: string]: React.FC } = {
  Account,
  AccountBehalf,
  Add,
  Administration,
  ArrowDown,
  ArrowLeft,
  ArrowNavUp,
  ArrowNavDown,
  ArrowRight,
  ArrowUp,
  AttentionSign,
  Cancel,
  Chat,
  CheckFilled,
  Checkmark,
  Close,
  Copy,
  DataConnection,
  Delete,
  Documentation,
  Download,
  Edit,
  Empty,
  EmptyMarket,
  Error,
  ExternalLink,
  File,
  FileImage,
  FileDoc,
  FilePDF,
  FilePPT,
  FileXLS,
  Folder,
  Files,
  FileAdd,
  GlobalUser,
  Home,
  InfoCircle,
  Key,
  Location,
  Mail,
  Market,
  Menu,
  None,
  Performance,
  Phone,
  Premium,
  Promotion,
  Remove,
  Reset,
  Restore,
  Save,
  Search,
  Settings,
  Share,
  SignIn,
  SignOut,
  Sales,
  Tiles,
  Warning,
};

const contentIcons: { [key: string]: React.FC } = {
  Activation,
  AdminPage,
  AnimalNutrition,
  Case,
  Connection,
  EDIInfo,
  Electronics,
  ErrorPage,
  EssentialChemicals,
  FoodAndHealth,
  Global,
  HomecareAndIndustrialCleaning,
  LubricantsAndMetalWorkingFluids,
  OtherApplications,
  PersonalCare,
  Pharma,
  PrincipalAdministration,
  RubberAndPlasticAdditives,
  SpecialityAgriHorti,
  TextilesLeatherAndPaper,
  FineChemicals,
  OrderHistory,
  AccountSettings,
  FixFormulation,
  EnhanceFormulation,
  SendBriefing,
  CheckmarkBig,
  ContentError,
  AnimalNutritionSolid,
  CaseSolid,
  ElectronicsSolid,
  EssentialChemicalsSolid,
  FineChemicalsSolid,
  FoodAndHealthSolid,
  GlobalSolid,
  HomecareAndIndustrialCleaningSolid,
  LubricantsAndMetalWorkingFluidsSolid,
  OtherApplicationsSolid,
  PersonalCareSolid,
  PharmaSolid,
  RubberAndPlasticAdditivesSolid,
  SpecialityAgriHortiSolid,
  TextilesLeatherAndPaperSolid,
  Support,
  UnderMaintenance,
};

const _iconNames = [
  'Activation',
  'Account',
  'AccountBehalf',
  'Add',
  'Administration',
  'AdminPage',
  'ArrowDown',
  'ArrowLeft',
  'ArrowNavUp',
  'ArrowNavDown',
  'ArrowRight',
  'ArrowUp',
  'AttentionSign',
  'Cancel',
  'Chat',
  'CheckFilled',
  'Checkmark',
  'Close',
  'Copy',
  'Connection',
  'DataConnection',
  'Delete',
  'Documentation',
  'Download',
  'EDIInfo',
  'Edit',
  'Empty',
  'EmptyMarket',
  'Error',
  'ErrorPage',
  'ExternalLink',
  'File',
  'FileImage',
  'FileDoc',
  'FilePDF',
  'FilePPT',
  'FileXLS',
  'Folder',
  'Files',
  'FileAdd',
  'GlobalUser',
  'Home',
  'InfoCircle',
  'Key',
  'Location',
  'Mail',
  'Market',
  'Menu',
  'Phone',
  'Promotion',
  'Remove',
  'Reset',
  'Restore',
  'Save',
  'SignIn',
  'SignOut',
  'OrderHistory',
  'AccountSettings',
  'FixFormulation',
  'EnhanceFormulation',
  'None',
  'SendBriefing',
  'CheckmarkBig',
  'Sales',
  'Tiles',
  'AnimalNutrition',
  'Case',
  'Electronics',
  'EssentialChemicals',
  'FineChemicals',
  'FoodAndHealth',
  'Global',
  'HomecareAndIndustrialCleaning',
  'LubricantsAndMetalWorkingFluids',
  'OtherApplications',
  'PersonalCare',
  'Pharma',
  'PrincipalAdministration',
  'RubberAndPlasticAdditives',
  'SpecialityAgriHorti',
  'TextilesLeatherAndPaper',
  'Settings',
  'Search',
  'Premium',
  'Performance',
  'Warning',
  'ContentError',
  'Share',
  'Support',
  'AnimalNutritionSolid',
  'CaseSolid',
  'ElectronicsSolid',
  'EssentialChemicalsSolid',
  'FineChemicalsSolid',
  'FoodAndHealthSolid',
  'GlobalSolid',
  'HomecareAndIndustrialCleaningSolid',
  'LubricantsAndMetalWorkingFluidsSolid',
  'OtherApplicationsSolid',
  'PersonalCareSolid',
  'PharmaSolid',
  'RubberAndPlasticAdditivesSolid',
  'SpecialityAgriHortiSolid',
  'TextilesLeatherAndPaperSolid',
  'UnderMaintenance',
] as const;

export const iconNames = [
  ...Object.keys(contentIcons),
  ...Object.keys(systemIcons),
];

export type IconName = (typeof _iconNames)[number];

export type IconProps = {
  /**
   * Name of the icon
   */
  name: IconName;
  /**
   * Flag for inline icon
   */
  inline?: boolean;
  /**
   * Title of the icon
   */
  title?: string;
  /**
   * Color of the icon
   */
  color?: string;
  /**
   * Color of the icon
   */
  hoverColor?: string;
  /**
   * Notification Number of changes
   */
  notification?: number;
  /**
   * A tooltip for shown when hovered over icon
   */
  tooltip?: string;
  className?: string;
};

if (iconNames.length !== _iconNames.length) {
  console.warn(
    `<Icon>: the number of allowed values name defined in the IconName type (${_iconNames.length}) does not match the number of available icons (${iconNames.length})`,
  );
}

export const Icon: React.FC<IconProps> = ({
  name,
  inline,
  title,
  color,
  hoverColor,
  notification = 0,
  tooltip,
  className,
}: IconProps) => {
  const isSystemIcon = useMemo((): boolean => {
    const matches = Object.keys(systemIcons).find((i) => i === name);
    return !!matches;
  }, [name]);

  const IconByName: React.FC<{ className: string; title?: string }> =
    useMemo(() => {
      if (isSystemIcon) {
        return systemIcons[name];
      } else {
        return contentIcons[name];
      }
    }, [name, isSystemIcon]);

  const getNotificationWidth = (notification: number): string => {
    if (notification > 999) return 'w-6';
    if (notification > 99) return 'w-5';
    if (notification > 9) return 'w-4';

    return 'w-3';
  };

  return typeof IconByName !== 'undefined' ? (
    <div
      className={`${notification > 0 ? 'relative' : 'h-full w-full'}${
        tooltip ? ' tooltip' : ''
      }`}
    >
      <IconByName
        className={`max-h-full w-auto ${inline ? 'inline' : 'm-auto block'} ${
          isSystemIcon || color ? 'fill-current' : ''
        } ${color ? 'text-' + color : ''} ${
          hoverColor ? 'hover:text-' + hoverColor : ''
        } ${className || ''}`}
        title={title}
      />
      {tooltip && (
        <span className="tooltiptext text-2xs select-none">
          <div className="mx-1">{tooltip}</div>
        </span>
      )}
      {notification > 0 && (
        <span
          className={`text-2xs left-15px top-15px absolute flex justify-center rounded-full bg-red-300 font-semibold text-white ${
            notification === 0 ? 'hidden' : getNotificationWidth(notification)
          }`}
        >
          {notification}
        </span>
      )}
    </div>
  ) : null;
};
