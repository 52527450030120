import { Icon } from 'components/Icon';
import { Link } from 'components/Link/Link';
import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { useTranslation } from 'react-i18next';

type PrincipalTableRowProps = {
  logoUrl?: string;
  principalName: string;
  principalId: string;
  onPrincipalSelection: (principalId: string) => void;
};
export const PrincipalTableRow: React.FC<PrincipalTableRowProps> = ({
  logoUrl,
  principalName,
  principalId,
  onPrincipalSelection,
}: PrincipalTableRowProps) => {
  const { t } = useTranslation('translation');
  const noLogoAvailable = '../../../icons/system/account-behalf.svg';
  return (
    <>
      <TableRow hoverable>
        <TableCell>
          <div className="flex items-center">
            <div className="h-6 w-6 rounded bg-white">
              {logoUrl ? (
                <img src={logoUrl ?? noLogoAvailable} alt="logo" />
              ) : (
                <Icon name="AccountBehalf" />
              )}
            </div>
            <div className="ml-4">{principalName}</div>
          </div>
        </TableCell>
        <TableCell>
          <div className="flex justify-end">
            <Link
              onClick={() => onPrincipalSelection(principalId)}
              label={t('linksOrButtons:edit')}
              icon="Edit"
              iconPosition="left"
            />
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};
