export enum MainFeatureType {
  DASHBOARD = 'dashboard',
  PERFORMANCE = 'performance',
  DIGITAL_ACTIVITIES = 'digital-activities',
  MARKET_INTELLIGENCE = 'market-intelligence',
  SHARED_DOCUMENTS = 'shared-documents',
  DATA_CONNECTIONS = 'data-connections',
  API_KEY_GENERATOR = 'api-key-generator',

  ACCOUNT = 'account',
  PORTAL_MEMBERS = 'portal-members',
  COOKIE_STATEMENT = 'cookie-statement',
  TERMS_OF_USE = 'terms-of-use',
  API_DOCUMENTATION = 'api-documentation',
  ADMIN_CENTER = 'admin-center',
}

export enum SubFeatureType {
  DASHBOARD_KEY_SALES_NUMBERS = 'key-sales-numbers',

  PERFORMANCE_SALES = 'sales',
  PERFORMANCE_OPPORTUNITIES = 'opportunities',
  PERFORMANCE_SUPPLY_CHAIN = 'supply-chain',
  PERFORMANCE_NON_CONFORMITIES_REPORT = 'non-conformities-report',
  PERFORMANCE_PRODUCT_SAMPLES = 'product-samples',
  PERFORMANCE_LEADS = 'leads',

  DIGITAL_ACTIVITIES_CP_INSIGHTS = 'cp-insights',
  DIGITAL_ACTIVITIES_SUPPLIER_PAGES = 'supplier-pages',

  MARKET_INTELLIGENCE_MARKET_INSIGHTS = 'market-insights',
  MARKET_INTELLIGENCE_EXPANSION_OPPORTUNITIES = 'expansion-opportunities',
  MARKET_INTELLIGENCE_SOCIAL_LISTENINGS_REPORT = 'social-listenings-report',

  SHARED_DOCUMENTS_LATEST_ACTIVITY = 'latest-activity',
  SHARED_DOCUMENTS_ALL_DOCUMENTS = 'all-documents',

  DATA_CONNECTIONS_API_KEY_MANAGEMENT = 'api-key-management',
  DATA_CONNECTIONS_EDI_INFORMATION = 'edi-information',

  PRINCIPAL_ADMINISTRATION = 'principal-administration',
  GLOBAL_USERS = 'global-users',
}
