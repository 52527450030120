import { MaintenanceState } from 'components/MaintenanceState/MaintenanceState';
import {
  MainFeatureComponent,
  routes,
} from 'controllers/ContentController/Routes';
import { useCurrentUser, usePrincipals } from 'hooks/queries';
import { getCurrentPrincipal } from 'lib/currentPrincipal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  MainFeatureType,
  SubFeatureType,
} from 'shared/types/features/FeatureIds';
import { ExpansionOpportunitiesWithMap } from '../../components/ExpansionOpportunitiesWithMap';
import { Heading } from '../../components/Heading';
import { PermissionDenied } from '../../components/PermissionDenied/PermissionDenied';
import { Tab, Tabs } from '../../components/Tabs';
import { MarketInsightsTab } from './MarketInsightsTab';
import { SocialListeningReportTab } from './SocialListeningReportTab';

export const MarketIntelligence: MainFeatureComponent = ({ subFeatures }) => {
  const { tab: tabFromURL } = useParams<{ tab: string | undefined }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(
    tabFromURL || (subFeatures ? subFeatures[0].id : ''),
  );

  const { user } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(user.id, principals);

  useEffect(() => {
    if (selectedTab) return;
    if (
      subFeatures?.find(
        ({ id }) => id === SubFeatureType.MARKET_INTELLIGENCE_MARKET_INSIGHTS,
      )
    ) {
      setSelectedTab(SubFeatureType.MARKET_INTELLIGENCE_MARKET_INSIGHTS);
    } else if (
      subFeatures?.find(
        ({ id }) =>
          id === SubFeatureType.MARKET_INTELLIGENCE_EXPANSION_OPPORTUNITIES,
      )
    ) {
      setSelectedTab(
        SubFeatureType.MARKET_INTELLIGENCE_EXPANSION_OPPORTUNITIES,
      );
    }
  }, [selectedTab, subFeatures]);

  const userCanViewAtLeastOneTab = subFeatures && subFeatures.length > 0;

  useEffect(() => {
    const marketIntelligenceRoute = routes(currentPrincipal.slug).find(
      MainFeatureType.MARKET_INTELLIGENCE,
    );
    navigate(`${marketIntelligenceRoute.url}/${selectedTab}`);
    // Because when we reach /market-intelligence, there should always be a tab active
    // and the URL should reflect that.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  return (
    <React.Fragment>
      <div className="w-full">
        {userCanViewAtLeastOneTab ? (
          // Currently all users are able to see at least one tab, but we'll include this check for the sake of future-proofing
          <div className="w-full px-4 md:px-6">
            <div className="max-w-xl-content mx-auto">
              <div
                className="mb-7 pt-6 md:mb-9 md:pt-7"
                data-test="page-header"
              >
                <div className="flex flex-col items-baseline justify-between md:flex-row md:space-x-2">
                  <Heading
                    text={`${t('features:market-intelligence:heading')}`}
                    margin={0}
                    level="h1"
                  />
                </div>
              </div>
              <Tabs activeTab={selectedTab} onTabChange={setSelectedTab}>
                {[
                  ...(function* () {
                    if (
                      subFeatures.find(
                        ({ id }) =>
                          id ===
                          SubFeatureType.MARKET_INTELLIGENCE_MARKET_INSIGHTS,
                      ) &&
                      currentPrincipal.id
                    ) {
                      yield (
                        <Tab
                          key={
                            SubFeatureType.MARKET_INTELLIGENCE_MARKET_INSIGHTS
                          }
                          tabKey={
                            SubFeatureType.MARKET_INTELLIGENCE_MARKET_INSIGHTS
                          }
                          title={t(
                            'features:market-intelligence:marketInsights:tab',
                          )}
                          heading={t(
                            'features:market-intelligence:marketInsights:headline',
                            {
                              name: currentPrincipal.name,
                            },
                          )}
                        >
                          {subFeatures.find(
                            ({ id }) =>
                              id ===
                              SubFeatureType.MARKET_INTELLIGENCE_MARKET_INSIGHTS,
                          )?.maintenanceMode ? (
                            <MaintenanceState />
                          ) : (
                            <MarketInsightsTab
                              selectedPrincipal={currentPrincipal.id}
                            />
                          )}
                        </Tab>
                      );
                    }

                    if (
                      subFeatures.find(
                        ({ id }) =>
                          id ===
                          SubFeatureType.MARKET_INTELLIGENCE_EXPANSION_OPPORTUNITIES,
                      )
                    ) {
                      yield (
                        <Tab
                          key={
                            SubFeatureType.MARKET_INTELLIGENCE_EXPANSION_OPPORTUNITIES
                          }
                          tabKey={
                            SubFeatureType.MARKET_INTELLIGENCE_EXPANSION_OPPORTUNITIES
                          }
                          title={`${t(
                            'features:market-intelligence:expansionOpportunities:tab',
                          )}`}
                          heading={t(
                            'features:market-intelligence:expansionOpportunities:headline',
                          )}
                        >
                          {subFeatures.find(
                            ({ id }) =>
                              id ===
                              SubFeatureType.MARKET_INTELLIGENCE_EXPANSION_OPPORTUNITIES,
                          )?.maintenanceMode ? (
                            <MaintenanceState />
                          ) : (
                            <div className="my-4 md:mb-6">
                              <ExpansionOpportunitiesWithMap />
                            </div>
                          )}
                        </Tab>
                      );
                    }

                    if (
                      subFeatures.find(
                        ({ id }) =>
                          id ===
                          SubFeatureType.MARKET_INTELLIGENCE_SOCIAL_LISTENINGS_REPORT,
                      )
                    ) {
                      yield (
                        <Tab
                          key={
                            SubFeatureType.MARKET_INTELLIGENCE_SOCIAL_LISTENINGS_REPORT
                          }
                          tabKey={
                            SubFeatureType.MARKET_INTELLIGENCE_SOCIAL_LISTENINGS_REPORT
                          }
                          title={`${t(
                            'features:market-intelligence:socialListeningReport:tab',
                          )}`}
                        >
                          {subFeatures.find(
                            ({ id }) =>
                              id ===
                              SubFeatureType.MARKET_INTELLIGENCE_SOCIAL_LISTENINGS_REPORT,
                          )?.maintenanceMode ? (
                            <MaintenanceState />
                          ) : (
                            <div className="my-4 md:mb-6">
                              <SocialListeningReportTab
                                currentPrincipalId={currentPrincipal.id}
                              />
                            </div>
                          )}
                        </Tab>
                      );
                    }
                  })(),
                ]}
              </Tabs>
            </div>
          </div>
        ) : (
          <PermissionDenied />
        )}
      </div>
    </React.Fragment>
  );
};
