import { Alert } from 'components/Alert';
import { DropdownSingleSelect } from 'components/Dropdown/DropdownVariants/DropdownSingleSelect';
import { Icon } from 'components/Icon';
import {
  MandateSelection,
  MandateSelectionFilterProps,
} from 'components/MandateSelection/MandateSelection';
import { Modal } from 'components/Modal';
import { RoleSelector } from 'components/RoleSelector/RoleSelector';
import { ToggleSwitch } from 'components/ToggleSwitch';
import {
  useCRMMandates,
  useCurrentUser,
  usePrincipals,
  useUnits,
} from 'hooks/queries';
import { getCurrentPrincipal } from 'lib/currentPrincipal';
import { getUserTypeByEmail } from 'lib/rolesAndPermissions';
import { isObjectValuesFilled, validateEmailAddress } from 'lib/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Role } from 'shared/types/authorization';
import { BaseUserType } from 'utils/firebase';
import { UserUnits } from 'utils/firebase/collection/firebase-collection-types';
import { UserDetails } from '../UserDetails/UserDetails';

export type NewUserDetails = {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  role: string | undefined;
  mandateFilters: MandateSelectionFilterProps;
  units: { [key: string]: UserUnits };
};

type AddUserModalProps = {
  errorToShow?: string;
  initialUserData?: Partial<NewUserDetails>;
  isInputDisabled?: boolean;
  onCancel: () => void;
  onConfirm: (newUser: NewUserDetails) => void;
  selectedPrincipalId: string;
};

const isLocalOrDevelopment =
  process.env.REACT_APP_ENVIRONMENT === 'local' ||
  process.env.REACT_APP_ENVIRONMENT === 'development';

const createInitialUser = (
  selectedPrincipalId: string,
  initialUserData?: Partial<NewUserDetails>,
): NewUserDetails => ({
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  role: undefined,
  mandateFilters: {
    [selectedPrincipalId]: {
      hasAllMandates: false,
      portfolio: [],
      country: [],
      marketSegment: [],
      territory: [],
    },
  },
  units: {
    [selectedPrincipalId]: { currency: 'EUR', weight: 'MT' },
  },
  ...initialUserData,
});

export const AddUserModal: React.FC<AddUserModalProps> = ({
  errorToShow,
  initialUserData,
  isInputDisabled,
  onCancel,
  onConfirm,
  selectedPrincipalId,
}) => {
  const { t } = useTranslation();
  const { user, role } = useCurrentUser();
  const { unitDefinitions, weight, currency } = useUnits();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(user.id, principals);
  const [newUser, setNewUser] = useState<NewUserDetails>(
    createInitialUser(selectedPrincipalId, initialUserData),
  );
  const [isEmailValid, setIsEmailValid] = useState(true);

  const userFilters = newUser?.mandateFilters?.[currentPrincipal.id];

  const { data: mandates } = useCRMMandates({
    principalPri: currentPrincipal.id,
  });

  const { data: filteredMandates } = useCRMMandates({
    principalPri: currentPrincipal.id,
    ...(userFilters && {
      filter: {
        hasAllMandates: userFilters?.hasAllMandates,
        iso: userFilters?.country,
        marketSegment: userFilters?.marketSegment,
        portfolio: userFilters?.portfolio,
      },
    }),
  });

  const [errorMessage, setErrorMessage] = useState(errorToShow);

  const userDetailsPlaceholder: BaseUserType = {
    firstName: 'Alfonso',
    lastName: 'Rosser',
    email: 'email@domain.eu',
  };

  useEffect(() => {
    setErrorMessage(errorToShow);
  }, [errorToShow]);

  const onClickConfirm = newUser
    ? () => {
        onConfirm(newUser);
        setErrorMessage(errorToShow);
      }
    : undefined;

  const onChangeForm = (
    inputValue: Record<
      string,
      Record<string, string | string[] | Record<string, string[]>> | string
    >,
  ) => {
    setNewUser({ ...newUser, ...inputValue });
  };

  const validateNewUsersEmailAdress = (value: string) => {
    if (!isEmailValid && validateEmailAddress(value)) {
      setIsEmailValid(true);
    }
    if (user?.email?.toLowerCase() !== value) {
      setErrorMessage(undefined);
      onChangeForm({ email: value });
    }
    if (!validateEmailAddress(value)) {
      setIsEmailValid(false);
    }
  };

  const setUserHasAllMandates = useCallback(
    (isSelected: boolean) => {
      const updatedMandateFilters = {
        mandateFilters: {
          [selectedPrincipalId]: {
            hasAllMandates: isSelected,
            portfolio: [],
            country: [],
            marketSegment: [],
            territory: [],
          },
        },
      };

      setNewUser((prevUser) => ({
        ...prevUser,
        ...updatedMandateFilters,
      }));
    },
    [selectedPrincipalId],
  );

  const filteredMandatesCount = filteredMandates?.count.mandates ?? 0;

  const canNewUserRoleHaveMandateFilters =
    newUser.role !== Role.PRINCIPAL_DEVELOPER;

  return (
    <Modal
      isFullscreen={true}
      headingAlign="left"
      heading={t('modals:addUserModal:heading')}
      confirmText={t('modals:addUserModal:confirm')}
      onCancel={onCancel}
      onConfirm={onClickConfirm}
      isConfirmButtonDisabled={
        (newUser && !isObjectValuesFilled(newUser)) || !isEmailValid
      }
    >
      <form>
        <div className="text-grey-700">
          <p className="mb-6 mt-1">{t('modals:addUserModal:description')}</p>
          {isLocalOrDevelopment && (
            <div className="mb-2">
              <Alert
                message={t('modals:addUserModal:infoToCreateInternalUser')}
                flavour="info"
              />
            </div>
          )}
          <UserDetails
            initialUserData={initialUserData}
            isInputDisabled={isInputDisabled}
            placeholder={userDetailsPlaceholder}
            heading={t('modals:addUserModal:personalDetailsHeading')}
            isEmailValid={isEmailValid}
            errorMessage={errorMessage}
            validateNewUsersEmailAdress={validateNewUsersEmailAdress}
            onChangeForm={onChangeForm}
          />
          <fieldset>
            <label className="mb-1 mt-0 block font-medium">
              {t('modals:addUserModal:role')}
            </label>
            {/* TODO: userType is based on newUser.email to define roles to select, could/should be changed? */}
            <RoleSelector
              onChange={(newRole) => {
                onChangeForm({ role: newRole });
                setUserHasAllMandates(
                  !!newUser.mandateFilters[selectedPrincipalId]?.hasAllMandates,
                );
              }}
              userRole={role}
              userType={getUserTypeByEmail(
                isEmailValid ? newUser?.email : undefined,
              )}
            />
          </fieldset>
          <div className="my-7 hidden">
            <div className="mb-3 flex items-center">
              <h3 className="text-2xl font-medium">
                {t('modals:addUserModal:userSettingsHeading')}
              </h3>
              <span className="ml-1 w-3">
                <Icon name="InfoCircle" />
              </span>
            </div>
            <fieldset>
              <div className="grid grid-cols-2 gap-4">
                <div className="w-full">
                  <label className="mb-1 block font-medium">
                    {t('modals:addUserModal:currency')}
                  </label>
                  <DropdownSingleSelect
                    options={currency}
                    initialSelection={{
                      value: newUser.units[selectedPrincipalId]?.currency,
                      name:
                        unitDefinitions?.currency?.[
                          newUser.units[selectedPrincipalId]?.currency
                        ] || '',
                    }}
                    onChange={(option) => {
                      newUser?.units[selectedPrincipalId]?.currency !==
                        option?.value &&
                        onChangeForm({
                          units: {
                            ...newUser.units,
                            currency: option?.value.toString() || '',
                          },
                        });
                    }}
                  />
                </div>
                <div className="w-full">
                  <label className="mb-1 block font-medium">
                    {t(`modals:addUserModal:weightMetrics`)}
                  </label>
                  <DropdownSingleSelect
                    options={weight}
                    initialSelection={{
                      value: newUser.units[selectedPrincipalId]?.weight,
                      name:
                        unitDefinitions?.weight?.[
                          newUser.units[selectedPrincipalId]?.weight
                        ] || '',
                    }}
                    onChange={(option) => {
                      newUser?.units[selectedPrincipalId]?.weight !==
                        option?.value &&
                        onChangeForm({
                          units: {
                            ...newUser.units,
                            weight: option?.value.toString() || '',
                          },
                        });
                    }}
                  />
                </div>
              </div>
            </fieldset>
          </div>
          {canNewUserRoleHaveMandateFilters && (
            <div className="my-7">
              <div className="mb-3">
                <div className="mb-1 grid grid-flow-row grid-cols-2 ">
                  <h3 className="items-center text-2xl font-medium">
                    {t('modals:addUserModal:mandateSelectionHeading')}
                  </h3>
                  <div className="flex justify-end">
                    <ToggleSwitch
                      label={t('modals:addUserModal:toggleLabel')}
                      onChange={setUserHasAllMandates}
                    />
                  </div>
                </div>

                <div className="mb-2 grid grid-flow-row grid-cols-2">
                  <div className="text-text1 flex items-center justify-start">
                    {t(
                      `modals:addUserModal:numberOfSelectedMandates_${
                        filteredMandatesCount === 1 ? 'one' : 'other'
                      }`,
                      {
                        count: filteredMandatesCount,
                      },
                    )}
                  </div>
                  <div className="text-text2 flex items-center justify-end">
                    <span className="text-2xs">
                      {t('modals:addUserModal:editHasAllMandatesDescription')}
                    </span>
                    <span className="ml-1 w-4">
                      <Icon name="InfoCircle" />
                    </span>
                  </div>
                </div>
              </div>
              <MandateSelection
                mandates={mandates}
                handleMandateFilterChange={(mandateFiltersItems) =>
                  onChangeForm({
                    mandateFilters: {
                      [selectedPrincipalId]: mandateFiltersItems,
                    },
                  })
                }
                initMandateFilters={newUser?.mandateFilters}
                selectedPrincipal={currentPrincipal}
              />
            </div>
          )}
        </div>
      </form>
    </Modal>
  );
};
