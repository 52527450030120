import { Button, ButtonType } from 'components/Button';
import { DropdownFilter } from 'components/Dropdown/DropdownVariants/DropdownFilter';
import { DropdownOptionProps } from 'components/Dropdown/helper/DropdownTypes';
import { sortASC } from 'components/Dropdown/helper/helper';
import { LoadingBounce } from 'components/Loading';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type FilterOptions = {
  country: DropdownOptionProps[];
  marketSegment: DropdownOptionProps[];
  portfolio: DropdownOptionProps[];
  territory: DropdownOptionProps[];
};
export type FilterOptionsKey = keyof FilterOptions;
export type RawFilterValuesFromMandates = {
  [key: string]: string[];
};
export type FilterOptionsByPrincipal = {
  [key: string]: FilterOptions;
};

type OPFiltersProps = {
  filterOptions: FilterOptions;
  disableMarketSegments?: boolean;
  onChange: (newFilters: FilterOptions) => void;
};

export const OPFilters: React.FC<OPFiltersProps> = ({
  filterOptions,
  disableMarketSegments,
  onChange,
}) => {
  const [filters, setFilters] = useState<FilterOptions | null>(null);
  const [disableApplyButton, setDisableApplyButton] = useState(true);
  const { t } = useTranslation();

  const selectAllAvailableOptions = Object.entries(filterOptions).reduce(
    (newObj, [key, val]) => {
      newObj[key as keyof FilterOptions] = val.reduce((acc, item) => {
        if (item.childOptions) {
          acc = acc.concat(
            item.childOptions.map((childOption) => ({
              ...childOption,
              active: true,
            })),
          );
        } else {
          acc.push({ ...item, active: true });
        }
        return acc;
      }, [] as DropdownOptionProps[]);

      return newObj;
    },
    {} as FilterOptions,
  );

  const selectedFilterOptions = filters || selectAllAvailableOptions;

  const updateSavedOPFilters = (
    newSelectedOptions: DropdownOptionProps[],
    filterName: FilterOptionsKey,
  ) => {
    const newSelectedFilters = {
      ...selectedFilterOptions,
      [filterName]: newSelectedOptions,
    };

    setFilters(newSelectedFilters);
    setDisableApplyButton(false);
  };

  return selectedFilterOptions?.country &&
    selectedFilterOptions.marketSegment &&
    selectedFilterOptions.portfolio ? (
    <>
      <div className="flex">
        <DropdownFilter
          typeAhead
          selectAllLabel={t('placeholders:selectAll')}
          deselectAllLabel={t('placeholders:deselectAll')}
          label={t('features:operationalPerformance:filters:portfolio')}
          options={filterOptions.portfolio.sort(sortASC)}
          initialSelection={selectedFilterOptions?.portfolio}
          disabledMessage={t('placeholders:dropdownAll')}
          onChange={(options) => updateSavedOPFilters(options, 'portfolio')}
        />

        <div className="mx-3">
          <DropdownFilter
            typeAhead
            label={t('features:operationalPerformance:filters:country')}
            selectAllLabel={t('placeholders:selectAll')}
            deselectAllLabel={t('placeholders:deselectAll')}
            options={filterOptions.country.sort(sortASC).map((region) => {
              return {
                ...region,
                childOptions: region.childOptions?.sort(sortASC),
              };
            })}
            initialSelection={selectedFilterOptions?.country}
            onChange={(options) => updateSavedOPFilters(options, 'country')}
          />
        </div>

        {!!filterOptions.territory.length && (
          <div className="mr-3">
            <DropdownFilter
              typeAhead
              label={t('features:operationalPerformance:filters:territory')}
              selectAllLabel={t('placeholders:selectAll')}
              deselectAllLabel={t('placeholders:deselectAll')}
              options={filterOptions.territory.sort(sortASC)}
              initialSelection={selectedFilterOptions?.territory}
              onChange={(options) => updateSavedOPFilters(options, 'territory')}
              disabled={
                !selectedFilterOptions.country.some(
                  ({ value }) => value === 'USA',
                )
              }
            />
          </div>
        )}
        <DropdownFilter
          typeAhead
          selectAllLabel={t('placeholders:selectAll')}
          deselectAllLabel={t('placeholders:deselectAll')}
          label={t('features:operationalPerformance:filters:marketSegment')}
          options={filterOptions.marketSegment}
          disabled={disableMarketSegments}
          initialSelection={selectedFilterOptions?.marketSegment}
          disabledMessage={t('placeholders:dropdownAll')}
          onChange={(options) => updateSavedOPFilters(options, 'marketSegment')}
        />
      </div>
      <div className="mt-3 flex justify-end">
        <Button
          className="min-h-40px flex items-center"
          disabled={disableApplyButton}
          onClick={
            filters
              ? () => {
                  onChange(filters);
                  setDisableApplyButton(true);
                }
              : undefined
          }
          buttonType={ButtonType.ICON}
          label="Apply filter settings"
        />
      </div>
    </>
  ) : (
    <LoadingBounce />
  );
};
