import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { getFirebaseBearerToken } from 'lib/getFirebaseBearerToken';
import { Principal } from '../../utils/firebase/collection/firebase-collection-types';

export type Principals = {
  [id: string]: Principal;
};
export const usePrincipals = (): UseQueryResult<Principal[]> =>
  useQuery({
    queryFn: async () => {
      const token = await getFirebaseBearerToken();
      return await axios({
        url: `${process.env.REACT_APP_PORTAL_SERVICE_BASE_URL}/principals`,
        method: 'Get',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    queryKey: ['principals'],
    select: ({ data }) => data,
  });
