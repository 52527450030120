import { DropdownSingleSelect } from 'components/Dropdown/DropdownVariants/DropdownSingleSelect';
import { DropdownOptionProps } from 'components/Dropdown/helper/DropdownTypes';
import { routes } from 'controllers/ContentController/Routes';
import { useCurrentUser, usePrincipals } from 'hooks/queries';
import {
  getCurrentPrincipal,
  getPrincipalFromSlug,
  setSelectedPrincipalIdWithRedirect,
} from 'lib/currentPrincipal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  MainFeatureType,
  SubFeatureType,
} from 'shared/types/features/FeatureIds';
import { tracking } from '../../lib/tracking';
import { Icon } from '../Icon';
import { Modal } from '../Modal';

export const PrincipalSelectionBar: React.FC = () => {
  const [isModalHidden, setIsModalHidden] = useState<boolean>(true);
  const [
    selectedPrincipalDropdownSelection,
    setSelectedPrincipalDropdownSelection,
  ] = useState<string>();
  const { user, isGlobalUser } = useCurrentUser();

  const { data: principals } = usePrincipals();
  const currentRoute = useLocation().pathname;

  const { t } = useTranslation();
  const { currentPrincipal } = getCurrentPrincipal(user.id, principals);

  const adminCenterRoute = routes(currentPrincipal.id).find(
    MainFeatureType.ADMIN_CENTER,
  );
  const principalAdministrationRoute = routes(currentPrincipal.slug).find(
    SubFeatureType.PRINCIPAL_ADMINISTRATION,
  );
  const globalUsersRoute = routes(currentPrincipal.id).find(
    SubFeatureType.GLOBAL_USERS,
  );

  useEffect(() => {
    if (currentPrincipal) {
      setSelectedPrincipalDropdownSelection(currentPrincipal.slug);
    }
  }, [currentPrincipal, setSelectedPrincipalDropdownSelection]);

  const initialPrincipalSelection = useMemo(() => {
    return {
      name: currentPrincipal.name,
      value: currentPrincipal.slug,
    } as DropdownOptionProps;
  }, [currentPrincipal]);

  const showModal = () => setIsModalHidden(false);
  const { role } = useCurrentUser();

  const onModalConfirmHandler = () => {
    if (selectedPrincipalDropdownSelection) {
      const selectedPrincipal = getPrincipalFromSlug(
        selectedPrincipalDropdownSelection,
        principals,
      );

      tracking.push('user', 'User Switch Principal', {
        loginStatus: 'logged in',
        type: role,
        principalID: selectedPrincipal?.id,
      });

      setSelectedPrincipalIdWithRedirect(
        currentPrincipal.slug,
        selectedPrincipalDropdownSelection,
        user.id,
      );
    }
  };

  const onPrincipalChangeHandler = useCallback(
    (selectedOption: DropdownOptionProps | undefined) => {
      const principalId = (selectedOption as DropdownOptionProps)
        ?.value as string;
      if (principalId) setSelectedPrincipalDropdownSelection(principalId);
    },
    [setSelectedPrincipalDropdownSelection],
  );

  const onModalCancelClickHandler = useCallback(() => {
    setIsModalHidden(true);
  }, []);

  const routesWithoutPrincipalControls = [
    adminCenterRoute.url,
    globalUsersRoute.url,
    principalAdministrationRoute.url,
  ];
  return (
    <>
      {Object.keys(user.roles) && principals && principals.length > 0 && (
        <>
          <div className="z-10 flex w-full items-center justify-between bg-blue-400 py-2 pl-5 pr-3 text-white md:px-6">
            {routesWithoutPrincipalControls.includes(currentRoute) ? (
              <div className="text my-1">{`${t(
                'components:principalSelectionBar:allPrincipals',
              )}`}</div>
            ) : (
              <div className="flex items-center text-xs">
                <div className="mr-1 h-6 w-6">
                  {currentPrincipal.logoUrl ? (
                    <img
                      src={currentPrincipal.logoUrl}
                      className="rounded bg-white"
                      alt={`${currentPrincipal.name} - Logo`}
                    />
                  ) : (
                    <Icon name="AccountBehalf" inline={true} />
                  )}
                </div>
                <div data-testid="customer-info">
                  <span className="mr-1 inline">
                    {`${t('components:principalSelectionBar:onBehalf')}`}{' '}
                    {currentPrincipal.name}
                  </span>
                </div>
              </div>
            )}
            {(Object.keys(user.roles).length > 1 || isGlobalUser) &&
              !routesWithoutPrincipalControls.includes(currentRoute) && (
                <div
                  className="ml-4 flex shrink-0 cursor-pointer items-baseline text-xs"
                  onClick={showModal}
                  data-testid="customer-change"
                >
                  <span className="mr-1 w-4">
                    <Icon name="Edit" inline={true}></Icon>
                  </span>
                  <span>{t('components:principalSelectionBar:change')} </span>
                </div>
              )}
          </div>

          <Modal
            icon="AccountBehalf"
            heading={t('modals:changeCustomerSelection:prompt')}
            isHidden={isModalHidden}
            confirmText={t('modals:changeCustomerSelection:confirm')}
            onConfirm={onModalConfirmHandler}
            onCancel={onModalCancelClickHandler}
          >
            <DropdownSingleSelect
              height="50vh--90"
              initialSelection={initialPrincipalSelection}
              options={principals
                .map(({ name, slug }) => ({
                  name,
                  value: slug,
                }))
                .sort((a, b) => a.name.localeCompare(b.name))}
              onChange={onPrincipalChangeHandler}
              widthVariant="full-width"
            />
          </Modal>
        </>
      )}
    </>
  );
};
