import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getCurrentPrincipal } from 'lib/currentPrincipal';
import { getApiKeyScopeOptions } from 'utils/firebase/cloud-functions';
import { getFirebaseData } from './useApiKeys';
import { useCurrentUser } from './useCurrentUser';
import { usePrincipals } from './usePrincipals';

export type Scope = {
  name: string;
  description: string;
  active: boolean;
};

export const useScopes = (): UseQueryResult<Scope[], Error> => {
  const { user: currentUser } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(currentUser.id, principals);

  return useQuery({
    queryFn: async () =>
      await getApiKeyScopeOptions({
        bearerToken: (await getFirebaseData(currentPrincipal.id))?.bearerToken,
        principalId: currentPrincipal.id,
      }),
    queryKey: ['scopes'],
    select: ({ data }) => data,
    enabled: true,
    refetchOnWindowFocus: false,
  });
};
