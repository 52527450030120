import { getAuth } from 'firebase/auth';

export const getFirebaseBearerToken = async () => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    const token = await user.getIdToken();
    return token;
  }
};
