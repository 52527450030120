import { Checkbox } from 'components/Checkbox';
import { LoadingDots } from 'components/Loading';
import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { routes } from 'controllers/ContentController/Routes';

import {
  BaseUseUserResult,
  useCurrentUser,
  usePrincipals,
} from 'hooks/queries';
import { usePermission } from 'hooks/usePermission';
import { getCurrentPrincipal } from 'lib/currentPrincipal';
import { formatStandardDate } from 'lib/utils';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Permission, Role, UserType } from 'shared/types/authorization';
import { MainFeatureType } from 'shared/types/features/FeatureIds';
import { PortalMemberName } from './PortalMemberName';
import { ToggleUsersToDeleteFunc } from './PortalMembers';

type ReactPortalMemberTableRowProps = {
  user: BaseUseUserResult;
  selectedPrincipal: string;
  key?: number;
  toggleUsersToDelete: ToggleUsersToDeleteFunc;
  userIdsToDelete: string[];
  availableMandatesCount?: number;
  availablePortfolioCount?: number;
};

const showCountOrLoading = (count: number | undefined) => (
  <>{count || <LoadingDots />}</>
);

export const PortalMemberTableRow: React.FC<ReactPortalMemberTableRowProps> = ({
  user: { user, role: userRole, isGlobalUser: userIsGlobalUser, userType },
  selectedPrincipal = '',
  toggleUsersToDelete,
  userIdsToDelete,
  availableMandatesCount,
  availablePortfolioCount,
}: ReactPortalMemberTableRowProps) => {
  const { user: currentUser } = useCurrentUser();
  const { data: principals } = usePrincipals();
  const { currentPrincipal } = getCurrentPrincipal(currentUser.id, principals);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userIsChecked = userIdsToDelete.includes(user.id);
  const isGlobalUserOrCurrentUser =
    user.id === currentUser?.id || userIsGlobalUser;

  const displayRole =
    userRole === Role.GLOBAL_USER_EDIT ? Role.GLOBAL_USER_VIEW : userRole;

  const { userHasPermission } = usePermission();
  const userCanEditMembers = userHasPermission(Permission.USER_ROLE_MGMT_EDIT);

  const UserMandateCount = useCallback(() => {
    return (
      <>
        {userIsGlobalUser ||
        user.mandateFilters?.[selectedPrincipal]?.hasAllMandates
          ? showCountOrLoading(availableMandatesCount)
          : user.assignedMandates?.[selectedPrincipal] ?? 0}
      </>
    );
  }, [
    availableMandatesCount,
    selectedPrincipal,
    user.assignedMandates,
    user.mandateFilters,
    userIsGlobalUser,
  ]);

  const UserPortfolioCount = useCallback(() => {
    return (
      <>
        {userIsGlobalUser ||
        user.mandateFilters?.[selectedPrincipal]?.hasAllMandates
          ? showCountOrLoading(availablePortfolioCount)
          : user.mandateFilters?.[selectedPrincipal]?.portfolio.length ?? 0}
      </>
    );
  }, [
    availablePortfolioCount,
    selectedPrincipal,
    user.mandateFilters,
    userIsGlobalUser,
  ]);

  const MandateCellContent = () => (
    <div className="inline-flex flex-row">
      <UserMandateCount /> / {showCountOrLoading(availableMandatesCount)}
    </div>
  );

  const PortfolioCellContent = () => (
    <div className="inline-flex flex-row">
      <UserPortfolioCount /> / {showCountOrLoading(availablePortfolioCount)}
    </div>
  );

  return (
    <TableRow
      key={user.id}
      hoverable
      onClick={() => {
        navigate(
          // TODO: MEMBER DETAILS ROUTE?
          `${routes(currentPrincipal.slug)
            .find(MainFeatureType.PORTAL_MEMBERS)
            .url.replace('/:uid', '')}/${user.id}`,
        );
      }}
    >
      {userCanEditMembers && (
        <TableCell
          onClick={(ev) => {
            ev.stopPropagation();
          }}
        >
          <Checkbox
            onChange={(isChecked) => toggleUsersToDelete([user.id], isChecked)}
            initialChecked={userIsChecked}
            disabled={isGlobalUserOrCurrentUser}
          />
        </TableCell>
      )}
      <TableCell>
        <PortalMemberName name={user.name} email={user.email?.toLowerCase()} />
      </TableCell>
      <TableCell>
        {user.signedIn ? (
          formatStandardDate(user.creationTime)
        ) : (
          <span className="text-grey-600">
            {t('features:portal-members:labels:unknownDate')}
          </span>
        )}
      </TableCell>
      <TableCell>
        {user.email ? (
          t(
            `features:portal-members:labels:userTypes:${
              userType === UserType.GLOBAL ? UserType.INTERNAL : userType
            }`,
          )
        ) : (
          <span className="text-grey-600">{t('labels:unknown')}</span>
        )}
      </TableCell>
      <TableCell>
        {t(`features:portal-members:labels:roles:${displayRole}`) || (
          <span className="text-grey-600">{t('labels:unknown')}</span>
        )}
      </TableCell>
      <TableCell>
        <MandateCellContent />
      </TableCell>
      <TableCell>
        <PortfolioCellContent />
      </TableCell>
    </TableRow>
  );
};
