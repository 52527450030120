import { Icon } from 'components/Icon';
import {
  InternalRoute,
  MainMenuConfig,
} from 'controllers/ContentController/Routes';
import { TFunction } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import { BigMenuProps, siteHeaderStyles } from './SiteHeader';

type AuxiliaryProps = BigMenuProps & {
  onMenuItemClick: () => void;
  getCurrentPath: () => string;
  getMenuItems: (menuSection: string) => (InternalRoute & {
    mainMenuConfig: MainMenuConfig;
  })[];
  toggleBigMenu: () => void;
  isAuthEmpty: boolean;
  signOut: () => void;
  dashboardRoute: InternalRoute;
  t: TFunction;
};

export const AuxiliaryNav: React.FC<AuxiliaryProps> = ({
  onMenuItemClick,
  bigMenu,
  toggleBigMenu,
  getCurrentPath,
  getMenuItems,
  isAuthEmpty,
  signOut,
  dashboardRoute,
  t,
}) => {
  const bigMenuStates = {
    menuLinks: bigMenu ? siteHeaderStyles.bigMenuLinks : '',
    menuIcons: bigMenu ? siteHeaderStyles.bigMenuIcons : 'lg:mx-auto',
    menuLabels: bigMenu ? siteHeaderStyles.bigMenuLabels : 'md:hidden',
  };
  return (
    <>
      {isAuthEmpty ? (
        <>
          <Link
            onClick={onMenuItemClick}
            key="menu-item-home"
            to={dashboardRoute.url}
            title={t('labels:signIn')}
            className={`flex h-8 py-1 hover:bg-blue-100 md:h-auto md:flex-col md:justify-center md:py-0 lg:h-6 lg:py-1 ${bigMenuStates.menuLinks}  md:box-content md:w-4 md:px-3 lg:w-full lg:px-0`}
          >
            <span
              className={`${siteHeaderStyles.menuItemSpanBase} ${bigMenuStates.menuIcons}`}
            >
              <Icon name="SignIn" title={t('labels:signIn')} />
            </span>
            <span className={`${bigMenuStates.menuLabels} ml-2 md:ml-0`}>
              {t('labels:signIn')}
            </span>
          </Link>
        </>
      ) : (
        <>
          {getMenuItems('secondary').map(({ url, mainMenuConfig }) => (
            <Link
              onClick={onMenuItemClick}
              key={`menu-item-${mainMenuConfig.title}`}
              to={url}
              title={t(`linksOrButtons:navigation:${mainMenuConfig.title}`)}
              className={`flex h-8 items-center py-1 hover:bg-blue-100 md:h-auto md:flex-col md:justify-center md:py-0 lg:h-6 lg:py-1 ${
                bigMenuStates.menuLinks
              } md:box-content md:w-4 md:px-3 lg:w-full lg:px-0 ${
                mainMenuConfig.title === getCurrentPath() && 'bg-blue-100'
              }`}
            >
              <span
                className={`${siteHeaderStyles.menuItemSpanBase} ${bigMenuStates.menuIcons}`}
              >
                <Icon
                  name={mainMenuConfig.icon}
                  title={t(`linksOrButtons:navigation:${mainMenuConfig.title}`)}
                />
              </span>
              <span className={`${bigMenuStates.menuLabels} ml-2 md:ml-0`}>
                {t(`linksOrButtons:navigation:${mainMenuConfig.title}`)}
              </span>
            </Link>
          ))}

          <Link
            key="menu-item-signout"
            to={dashboardRoute.url}
            className={`flex h-8 items-center py-1 hover:bg-blue-100 md:h-auto md:flex-col md:justify-center md:py-0 lg:h-6 lg:py-1 ${bigMenuStates.menuLinks} md:box-content md:w-4 md:px-3 lg:w-full lg:px-0`}
            onClick={signOut}
          >
            <span
              className={`${siteHeaderStyles.menuItemSpanBase} ${bigMenuStates.menuIcons}
              `}
            >
              <Icon name="SignOut" title={t('labels:signOut')} />
            </span>
            <span className={`${bigMenuStates.menuLabels} ml-2 md:ml-0`}>
              {t('labels:signOut')}
            </span>
          </Link>
        </>
      )}
      <div
        data-testid="big-menu-toggle"
        key="menu-item-toggle"
        className={`hidden h-8 py-1 hover:bg-blue-100 md:h-auto md:flex-col md:justify-center md:py-0 lg:flex lg:h-6 lg:py-1 ${bigMenuStates.menuLinks} cursor-pointer select-none md:box-content md:w-4 md:px-3 lg:w-full lg:px-0`}
        onClick={toggleBigMenu}
      >
        <span
          className={`${siteHeaderStyles.menuItemSpanBase} ${bigMenuStates.menuIcons}`}
        >
          {bigMenu ? (
            <Icon name="ArrowLeft" title={t('labels:collapseMenu')} />
          ) : (
            <Icon name="ArrowRight" title={t('labels:expandMenu')} />
          )}
        </span>
        <span
          className={`${
            bigMenu ? siteHeaderStyles.bigMenuLabels : 'hidden'
          } ml-2 md:ml-0`}
        >
          {t('labels:collapseMenu')}
        </span>
      </div>
    </>
  );
};
